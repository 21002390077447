import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import { SchoolType, SchoolListDocument } from 'api/generated'

export const useSchools = (options = {}) => {
    const [response, setResponse] = useState<SchoolType[]>([])

    const { data, loading, error, refetch } = useQuery(
        SchoolListDocument,
        options
    )

    useEffect(() => {
        if (data?.schoolList) {
            const schools: SchoolType[] = data?.schoolList.edges.map(
                edge => edge?.node as SchoolType
            )
            setResponse(schools)
        }
    }, [data])

    return {
        schools: response,
        loading,
        error,
        refetch,
    }
}
