import { useCallback, useEffect, useState } from 'react'

import { useQuery, DocumentNode } from '@apollo/client'
import { debounce, get, omit } from 'lodash'
import { GridColDef } from '@mui/x-data-grid'

interface ConnectionEdge<T> {
    node: T
}
interface TableConfig {
    itemsPerPage: number
    query: DocumentNode
    name: string
    path: string
    columns: GridColDef[]
}

interface TablePaginationParams {
    first: number | null
    after: string | null
    search: string
    [key: string]: any
}

const useTable = <T>(tableConfig: TableConfig, defaultParams = {}) => {
    const itemsPerPage = (): number => tableConfig.itemsPerPage || 10

    const [params, setParams] = useState<TablePaginationParams>({
        first: itemsPerPage(),
        after: null,
        search: '',
    })

    const [searchStr, setSearchStr] = useState('')

    const [currentPage, setCurrentPage] = useState(0)

    const [responseData, setResponseData] = useState({
        rowsCount: 0,
        rows: [],
        startCursor: null,
        endCursor: null,
    })
    const buildQuery = (queryParams: TablePaginationParams) => {
        return {
            ...defaultParams,
            ...omit(
                queryParams,
                Object.keys(queryParams).filter(
                    (p: keyof TablePaginationParams) => {
                        if (Array.isArray(queryParams[p])) {
                            return !queryParams[p].length
                        }
                        return (
                            [undefined, null, ''].indexOf(queryParams[p]) !== -1
                        )
                    }
                )
            ),
        }
    }

    const { data, loading, error, refetch } = useQuery(tableConfig.query, {
        errorPolicy: 'all',
        fetchPolicy: 'cache-first',
        variables: buildQuery(params),
    })

    const debounceUpdateParams = useCallback(debounce(setParams, 1000), [
        setParams,
    ]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!loading) {
            if (data) {
                const pageInfo = get(data, `${tableConfig.path}.pageInfo`, {})
                setResponseData({
                    rowsCount: get(data, `${tableConfig.path}.totalCount`, 0),
                    rows: get(data, `${tableConfig.path}.edges`, []).map(
                        (edge: ConnectionEdge<T>) => edge.node
                    ),
                    startCursor: pageInfo.startCursor,
                    endCursor: pageInfo.endCursor,
                })
            } else {
                setResponseData({
                    rowsCount: 0,
                    rows: [],
                    startCursor: null,
                    endCursor: null,
                })
            }
        }
    }, [data, tableConfig.path, loading])

    const refetchData = () => {
        refetch(buildQuery(params))
    }

    const resetPage = () => ({
        first: itemsPerPage(),
        after: null,
        before: null,
        last: null,
    })

    const onNextPage = () => {
        if (loading) return
        setParams((prevState: TablePaginationParams) => {
            return {
                ...prevState,
                before: null,
                after: responseData.endCursor,
                first: itemsPerPage(),
                last: null,
            }
        })
    }

    const onPrevPage = () => {
        if (loading) return
        setParams((prevState: TablePaginationParams) => {
            return {
                ...prevState,
                before: responseData.startCursor,
                first: null,
                last: itemsPerPage(),
                after: null,
            }
        })
    }

    const onPageChange = (page: number) => {
        if (loading) return
        if (currentPage > page) {
            setCurrentPage(prevState => prevState - 1)
            onPrevPage()
        } else if (currentPage < page) {
            setCurrentPage(prevState => prevState + 1)
            onNextPage()
        }
    }

    const getFirstPage = () => ({
        first: itemsPerPage(),
        after: null,
        before: null,
        last: null,
    })

    const setSearch = (search: string) => {
        if (search !== searchStr) {
            debounceUpdateParams({
                ...params,
                search,
                ...getFirstPage(),
            })
            setSearchStr(search)
            setCurrentPage(0)
        }
    }

    const debounceSetParam = (name: string, search: string) => {
        if (search !== searchStr) {
            debounceUpdateParams({
                ...params,
                [name]: search,
                ...getFirstPage(),
            })
            setSearchStr(search)
            setCurrentPage(0)
        }
    }

    const setParam = (key: string | object, value?: string) => {
        setCurrentPage(0)
        if (typeof key === 'object') {
            setParams((prevState: TablePaginationParams) => {
                return { ...prevState, ...key, ...resetPage() }
            })
        } else {
            setParams((prevState: TablePaginationParams) => {
                return { ...prevState, [key]: value || null, ...resetPage() }
            })
        }
    }

    const getParam = (key: string) => {
        return params[key] || ''
    }

    return {
        rows: responseData.rows,
        rowsCount: responseData.rowsCount,
        itemsPerPage: itemsPerPage(),
        refetchData,
        onNextPage,
        onPrevPage,
        onPageChange,
        currentPage,
        setSearch,
        debounceSetParam,
        setParam,
        getParam,
        searchStr,
        loading,
        error,
    }
}

export default useTable
