import {
    FormControl,
    InputLabel,
    FormHelperText,
    Typography,
    Button,
    Stack,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'

import { PasswordField } from 'components/inputs'
import { Confirmation, Form } from 'components/common'
import { useAuth } from 'hooks/auth'
import { SecurityUpdateFormScheme } from './index.schema'
import { EmailForm } from '../index'
import { useUser, useUserAPI } from 'hooks/user'
import { useDialog } from '../../../hooks/common'

const SecurityUpdateForm = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { changePassword, logout } = useAuth()
    const { user, refetch } = useUser()
    const { update } = useUserAPI()
    const { open, close } = useDialog()

    const handleChangeEmail = ({ email }: { email: string }) => {
        update({
            input: {
                email,
            },
        })
            .then(response => {
                if (response?.success) {
                    logout()
                }
            })
            .catch(e => {
                enqueueSnackbar(e.message, { variant: 'error' })
            })
    }

    const handleShowConfirmation = ({ email }: { email: string }) => {
        open({
            component: Confirmation,
            props: {
                onCancel: close,
                onApprove: () => handleChangeEmail({ email }),
                message:
                    'We will send a letter to your new email. Use the link inside to verify your new email.',
                caption: 'Once you click on "Confirm" you are logged out.',
                approveTitle: 'Confirm',
            },
            options: {
                title: `Confirm changing of email?`,
                onClose: close,
                sx: {
                    '& .MuiDialogContent-root': {
                        minWidth: 0,
                        maxWidth: '500px',
                    },
                },
            },
        })
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            newPassword: '',
            newPassword2: '',
        },
        validationSchema: SecurityUpdateFormScheme,
        onSubmit: values => {
            changePassword({
                currentPassword: values.password,
                newPassword: values.newPassword,
                repeatPassword: values.newPassword2,
            })
                .then(response => {
                    if (response?.success) {
                        enqueueSnackbar('Password changed')
                    } else {
                        enqueueSnackbar('Error happened')
                    }
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' })
                })
        },
    })

    return (
        <Stack
            gap="24px"
            sx={{
                padding: '20px',
                width: '320px',
            }}
        >
            <EmailForm
                isConfirmed={!!user?.isEmailConfirmed}
                onSubmit={handleShowConfirmation}
                email={user?.email as string}
            />
            <Form onSubmit={formik.handleSubmit}>
                <Stack gap="16px">
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="firstName">
                            Password{' '}
                            <Typography variant="caption" color="#CE321E">
                                *
                            </Typography>
                        </InputLabel>
                        <PasswordField
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            name="password"
                            error={
                                formik.touched.password &&
                                Boolean(formik.errors.password)
                            }
                        />
                        {formik.touched.password && formik.errors.password && (
                            <FormHelperText>
                                {formik.errors.password}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="newPassword">
                            New password{' '}
                            <Typography variant="caption" color="#CE321E">
                                *
                            </Typography>
                        </InputLabel>
                        <PasswordField
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            name="newPassword"
                            error={
                                formik.touched.newPassword &&
                                Boolean(formik.errors.newPassword)
                            }
                        />
                        {formik.touched.newPassword &&
                            formik.errors.newPassword && (
                                <FormHelperText>
                                    {formik.errors.newPassword}
                                </FormHelperText>
                            )}
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="newPassword2">
                            Repeat new password{' '}
                            <Typography variant="caption" color="#CE321E">
                                *
                            </Typography>
                        </InputLabel>
                        <PasswordField
                            value={formik.values.newPassword2}
                            onChange={formik.handleChange}
                            name="newPassword2"
                            error={
                                formik.touched.newPassword2 &&
                                Boolean(formik.errors.newPassword2)
                            }
                        />
                        {formik.touched.newPassword2 &&
                            formik.errors.newPassword2 && (
                                <FormHelperText>
                                    {formik.errors.newPassword2}
                                </FormHelperText>
                            )}
                    </FormControl>
                    <Button
                        type="submit"
                        sx={{
                            maxWidth: '270px',
                        }}
                    >
                        Save
                    </Button>
                </Stack>
            </Form>
        </Stack>
    )
}

export default SecurityUpdateForm
