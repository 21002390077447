import { object, string } from 'yup'

import { emailValidator } from '../index.validators'

export interface EmailFormValues {
    email: string
}

export const EmailFormScheme = object({
    email: string()
        .test(emailValidator)
        .required('Enter a valid email address'),
})
