import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    UserUpdateDocument,
    MeDocument,
    UserType,
    MutationUserUpdateArgs,
    UserUpdateMutation,
    ClubListQueryVariables,
    ClubType,
    ClubListDocument,
    UserListDocument,
    UserListQueryVariables,
    UserDetailsDocument,
} from 'api/generated'

export const useUser = () => {
    const { data, loading, error, refetch } = useQuery(MeDocument, {
        errorPolicy: 'all',
    })

    let user: UserType | undefined

    if (data?.me) {
        user = data.me as UserType
    }

    return {
        user,
        loading,
        error,
        refetch,
    }
}

export const useUserAPI = () => {
    const [updateUser] = useMutation(UserUpdateDocument)

    const update = (data: MutationUserUpdateArgs) => {
        return updateUser({ variables: data }).then(
            (response: FetchResult<UserUpdateMutation>) =>
                response?.data?.userUpdate
        )
    }

    return {
        update,
    }
}

export const useUserDetails = (id: string, options = {}) => {
    const { data, error, loading, refetch } = useQuery(UserDetailsDocument, {
        variables: {
            id,
        },
        errorPolicy: 'all',
        ...options,
    })

    let user: UserType | undefined

    if (data?.userDetails) {
        user = data.userDetails as UserType
    }

    return {
        user,
        loading,
        error,
        refetch,
    }
}

export const useUsers = (variables: UserListQueryVariables = {}) => {
    const [response, setResponse] = useState<UserType[]>([])
    const [total, setTotal] = useState(0)

    const { data, loading, error, refetch } = useQuery(UserListDocument, {
        variables,
    })

    useEffect(() => {
        if (data?.userList) {
            const total = data?.userList.totalCount
            const users: UserType[] = data?.userList.edges.map(
                edge => edge?.node as UserType
            )
            setResponse(users)
            setTotal(total as number)
        }
    }, [data])

    return {
        users: response,
        total,
        loading,
        error,
        refetch,
    }
}
