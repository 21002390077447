import { useQuery } from '@apollo/client'

import { ConfigDocument } from 'api/generated'

export const useConfig = (options = {}) => {
    const { data, loading } = useQuery(ConfigDocument, {
        fetchPolicy: 'cache-first',
        ...options,
    })

    return {
        loading,
        data,
    }
}
