import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import {
    SearchDocument,
    SearchQueryVariables,
    ClubType,
    EventType,
    UserType,
    UsersFilterDocument,
    ClubTypeConnection,
    EventTypeConnection,
    SchoolTypeConnection,
} from 'api/generated'

export const useSearch = (
    variables: SearchQueryVariables = {},
    options = {}
) => {
    const [response, setResponse] = useState<{
        clubs: ClubType[]
        events: EventType[]
        users: UserType[]
    }>({ clubs: [], users: [], events: [] })

    const { data, loading, error, refetch } = useQuery(SearchDocument, {
        variables,
        ...options,
    })

    useEffect(() => {
        if (data) {
            const clubs: ClubType[] =
                data?.clubList?.edges.map(edge => edge?.node as ClubType) || []
            const events: EventType[] =
                data?.eventList?.edges.map(edge => edge?.node as EventType) ||
                []
            const users: UserType[] =
                data?.userList?.edges.map(edge => edge?.node as UserType) || []

            setResponse({ clubs, events, users })
        }
    }, [data])

    return {
        data: response,
        loading,
        error,
        refetch,
    }
}

interface UsersFilterPayload {
    clubs: ClubTypeConnection
    events: EventTypeConnection
    schools: SchoolTypeConnection
}

export const useUserSearchFiltersData = (skip = false) => {
    const [response, setResponse] = useState<UsersFilterPayload | null>(null)

    const { data } = useQuery(UsersFilterDocument, {
        skip,
    })

    useEffect(() => {
        if (data) {
            setResponse(data as UsersFilterPayload)
        }
    }, [data])

    return {
        data: response,
    }
}
