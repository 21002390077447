import React from 'react'

import { useDialog } from 'hooks/common'
import { Dialog } from 'components/common'

const ReactiveDialog = (): JSX.Element | null => {
    const {
        data: { component: Component, options, props },
    } = useDialog()

    return Component && options ? (
        <Dialog {...options}>
            <Component {...props} />
        </Dialog>
    ) : null
}

export default ReactiveDialog
