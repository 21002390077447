import { useState, useEffect } from 'react'

const useHeaderHeight = () => {
    const [headerHeight, setHeaderHeight] = useState<number>(0)

    useEffect(() => {
        const header = document.getElementById('header')
        if (header) {
            setHeaderHeight(header.offsetHeight)
        }

        const handleResize = () => {
            if (header) {
                setHeaderHeight(header.offsetHeight)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return headerHeight
}

export default useHeaderHeight
