import { object, string } from 'yup'

export interface AlbumFormValues {
    name: string
}

export const AlbumFormInitialValues = {
    name: '',
}

export const AlbumFormScheme = object({
    name: string()
        .max(32, 'Max characters limit is exceeded')
        .required('Required field'),
})
