import React, { FC, useState } from 'react'

import {
    Button,
    FormControl,
    FormHelperText,
    InputBase,
    InputLabel,
    Stack,
} from '@mui/material'
import { useFormik } from 'formik'

import { Form } from 'components/common'
import { PhotoFormScheme, PhotoFormValues } from './index.schema'
import { AlbumType, PhotoType } from 'api/generated'
import { MultipleAutocompleteField } from '../../inputs'

interface PhotoFormProps {
    onSubmit: (data: PhotoFormValues) => void
    photo?: PhotoType
    onCancel: () => void
    albums: AlbumType[]
}

const PhotoForm: FC<PhotoFormProps> = ({
    albums,
    onSubmit,
    photo,
    onCancel,
}) => {
    const formik = useFormik({
        initialValues: {
            name: photo?.name as string,
            id: photo?.id as string,
            albumIds: photo?.albums.edges
                .map(e => e?.node)
                .map(a => a?.id) as string[],
        },
        validationSchema: PhotoFormScheme,
        onSubmit,
    })

    const albumOptions = albums.map(a => ({ value: a.id, label: a.name }))

    return (
        <Form onSubmit={formik.handleSubmit} sx={{ width: '440px' }}>
            <Stack gap="24px">
                <FormControl variant="standard">
                    <InputLabel shrink htmlFor="name">
                        Photo Name
                    </InputLabel>
                    <InputBase
                        autoFocus
                        id="name"
                        fullWidth
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.name && Boolean(formik.errors.name)
                        }
                    />
                    {formik.touched.name && formik.errors.name && (
                        <FormHelperText>{formik.errors.name}</FormHelperText>
                    )}
                </FormControl>
                {albums.length > 0 && (
                    <MultipleAutocompleteField
                        limitTags={2}
                        withSelectAll
                        value={
                            albumOptions?.filter(album => {
                                return (
                                    formik.values.albumIds as string[]
                                )?.includes(album?.value as string)
                            }) || []
                        }
                        options={albumOptions || []}
                        label="Add to albums"
                        placeholder="Select"
                        onChange={value =>
                            formik.setFieldValue('albumIds', value as string[])
                        }
                    />
                )}
                <Stack direction="row" gap="12px">
                    <Button onClick={onCancel} fullWidth variant="outlined">
                        Cancel
                    </Button>
                    <Button fullWidth type="submit">
                        Save
                    </Button>
                </Stack>
            </Stack>
        </Form>
    )
}
export default PhotoForm
