import React, { FC, forwardRef, MutableRefObject, Ref } from 'react'

import ReactDropzone, { FileRejection, DropzoneRef } from 'react-dropzone'
import { Box, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

import {
    MAX_PHOTOS_LIMIT,
    MAX_PHOTO_SIZE,
    PHOTO_ACCEPT_EXTENSIONS,
} from 'config/user.config'
import photoUpload from 'assets/images/photo-upload.svg'
import { Image } from 'components/common'

export interface DropzoneProps {
    onSelect: (files: File[]) => void
    onReject: (rejections: FileRejection[]) => void
    multiple?: boolean
    disabled?: boolean
    preview?: string | null
    PreviewProps: {
        sx: SxProps
    }
}

const Dropzone = forwardRef(
    (
        {
            onSelect,
            onReject,
            multiple = false,
            disabled = false,
            PreviewProps,
            preview = null,
        }: DropzoneProps,
        ref: Ref<DropzoneRef>
    ) => {
        return (
            <ReactDropzone
                multiple={multiple}
                disabled={disabled}
                ref={ref}
                maxFiles={MAX_PHOTOS_LIMIT}
                maxSize={MAX_PHOTO_SIZE}
                accept={PHOTO_ACCEPT_EXTENSIONS}
                onDrop={onSelect}
                onDropRejected={onReject}
            >
                {({ getRootProps, getInputProps }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            border: `1px solid #E4E4EB`,
                            cursor: 'pointer',
                            borderRadius: '11px',
                            background: '#F4F8FF',
                            height: '300px',
                            overflow: 'hidden',
                            '&:hover': {
                                borderColor: (theme: Theme) =>
                                    theme.colors.primary,
                            },
                            ...(disabled && { opacity: 0.5 }),
                        }}
                        {...getRootProps()}
                    >
                        {preview ? (
                            <Image
                                sx={{
                                    ...PreviewProps.sx,
                                    objectFit: 'scale-down',
                                    width: '100%',
                                    height: '100%',
                                }}
                                src={preview}
                            />
                        ) : (
                            <Image src={photoUpload} alt="icon" />
                        )}
                        <input {...getInputProps()} />
                    </Box>
                )}
            </ReactDropzone>
        )
    }
)

export default Dropzone
