import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/700.css'

import React from 'react'

import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { ApolloProvider } from '@apollo/client'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import './index.scss'

import { client } from 'api'
import { appTheme } from 'theme'
import App from 'components/app'
import Auth from 'components/auth'
import LandingPage from './components/pages/auth/LandingPage'
import { TokenStorage } from 'services'
import PrivacyPage from './components/pages/auth/PrivacyPage'
import TermsPage from './components/pages/auth/TermsPage'

declare global {
    interface Window {
        COMMIT_TAG: string
    }
}

declare module 'notistack' {
    interface VariantOverrides {
        notification: {
            data: any
            onCloseNotification: () => void
        }
    }
}

const container = document.getElementById('root')
const root = createRoot(container as Element)

root.render(
    <ThemeProvider theme={appTheme}>
        <ApolloProvider client={client}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={3000}
                    hideIconVariant
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <StyledEngineProvider injectFirst>
                        <CssBaseline />
                        <BrowserRouter>
                            {TokenStorage.isAuthenticated() ? (
                                <Routes>
                                    <Route path="/*" element={<App />} />
                                </Routes>
                            ) : (
                                <Routes>
                                    <Route path="/" element={<LandingPage />} />
                                    <Route
                                        path="/privacy"
                                        element={<PrivacyPage />}
                                    />
                                    <Route
                                        path="/terms"
                                        element={<TermsPage />}
                                    />
                                    <Route path="/*" element={<Auth />} />
                                </Routes>
                            )}
                        </BrowserRouter>
                    </StyledEngineProvider>
                </SnackbarProvider>
            </LocalizationProvider>
        </ApolloProvider>
    </ThemeProvider>
)
