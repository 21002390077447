import { Button, Stack, Typography } from '@mui/material'

interface ConfirmationProps {
    message?: string
    caption?: string
    approveTitle?: string
    onCancel: () => void
    onApprove: () => void
}

const Confirmation = ({
    approveTitle = 'Delete',
    message,
    caption,
    onApprove,
    onCancel,
}: ConfirmationProps) => {
    return (
        <Stack gap="24px" width="100%">
            <Typography fontSize="15px" color="#1d1e20" lineHeight="19px">
                {message}
            </Typography>
            {caption && (
                <Typography
                    variant="caption"
                    fontSize="14px"
                    color="#1d1e20"
                    lineHeight="19px"
                >
                    {caption}
                </Typography>
            )}
            <Stack direction="row" justifyContent="flex-end" gap="12px">
                <Button
                    variant="outlined"
                    color={approveTitle === 'Delete' ? 'error' : 'primary'}
                    onClick={onApprove}
                >
                    {approveTitle}
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </Stack>
        </Stack>
    )
}

export default Confirmation
