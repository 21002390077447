import React, { useState } from 'react'

import {
    InputAdornment,
    InputBaseProps,
    InputBase,
    IconButton,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const PasswordField = (props: InputBaseProps) => {
    const [showPassword, setShowPassword] = useState(false)

    const handleToggleVisibility = (): void => {
        setShowPassword(prevState => !prevState)
    }

    return (
        <InputBase
            type={showPassword ? 'text' : 'password'}
            sx={{
                position: 'relative',
                '& .MuiInputBase-input': { paddingRight: '60px' },
            }}
            endAdornment={
                <InputAdornment
                    position="end"
                    sx={{ position: 'absolute', right: '10px' }}
                >
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleVisibility}
                        onMouseDown={e => e.preventDefault()}
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }
            {...props}
        />
    )
}

export default PasswordField
