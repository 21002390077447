import { Button, DialogActions } from '@mui/material'

interface DialogButtonsProps {
    onSubmit?: () => void
    onCancel: () => void
    submitTitle?: string
    cancelTitle?: string
    submitButtonColor?: 'primary' | 'secondary'
    removePaddings?: boolean
}
const DialogButtons = ({
    onSubmit,
    onCancel,
    removePaddings = true,
    submitTitle = 'Done',
    cancelTitle = 'Cancel',
    submitButtonColor = 'primary',
}: DialogButtonsProps): JSX.Element => {
    return (
        <DialogActions>
            <Button fullWidth variant="outlined" onClick={onCancel}>
                {cancelTitle}
            </Button>
            <Button
                fullWidth
                type={!onSubmit ? 'submit' : 'button'}
                color={submitButtonColor}
                onClick={onSubmit}
            >
                {submitTitle}
            </Button>
        </DialogActions>
    )
}
export default DialogButtons
