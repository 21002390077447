import React from 'react'

import { Divider, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import Header from '../LandingPage/components/Header'
import { authPaths } from '../../../../routes'

const PrivacyPage = (): JSX.Element => {
    const navigate = useNavigate()

    const onSignUp = (): void => {
        navigate(`../${authPaths.signUp}`)
    }

    const onSignIn = (): void => {
        navigate(`../${authPaths.login}`)
    }

    const onAbout = (): void => {
        navigate(`../${authPaths.landing}#about`)
    }

    const onContacts = (): void => {
        navigate(`../${authPaths.landing}#footer`)
    }

    const onJoin = (): void => {
        navigate(`../${authPaths.landing}#join`)
    }

    return (
        <Stack
            sx={{
                maxWidth: '1142px',
                width: '100%',
                padding: '0 16px 46px',
                margin: 'auto',
                boxSizing: 'border-box',
            }}
            gap="32px"
        >
            <Header
                onSignUp={onSignUp}
                onSignIn={onSignIn}
                onAbout={onAbout}
                onContacts={onContacts}
                onJoin={onJoin}
            />
            <Stack divider={<Divider />} gap="24px">
                <Stack gap="16px">
                    <Typography variant="h5" fontWeight="700">
                        Information We Collect and How We Use It
                    </Typography>
                    <Typography variant="body1">
                        We use the Google Maps API solely for address lookup
                        purposes. It does not collect or store any user data,
                        and no permissions are required for this feature.
                    </Typography>
                    <Typography variant="body1">
                        We collect and store users&apos; email addresses only
                        for account-related purposes, such as activating your
                        account on KoolYard. No other personal information is
                        collected or stored. We do not share, track, or sell
                        your personal or business information to any third
                        parties.
                    </Typography>
                    <Typography variant="body1">
                        Lynked Inc.&apos;s use and transfer of information
                        received from Google APIs adheres to the Google API
                        Services User Data Policy, including the Limited Use
                        requirements.
                    </Typography>
                </Stack>
                <Stack gap="16px">
                    <Typography variant="h5" fontWeight="700">
                        Permissions on KoolYard
                    </Typography>
                    <Typography variant="body1">
                        KoolYard does not require any permissions related to the
                        Google Maps API, as it is only used for address lookup,
                        and no user data is collected or shared.
                    </Typography>
                </Stack>
                <Stack gap="16px">
                    <Typography variant="h5" fontWeight="700">
                        Can I Review or Revoke Permissions?
                    </Typography>
                    <Typography variant="body1">
                        Since KoolYard does not request any permissions from
                        users related to the Google Maps API or other external
                        services, there is no need for users to manage or revoke
                        such permissions.
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default PrivacyPage
