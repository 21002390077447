import React, { useState } from 'react'

import { Button, Stack, Typography, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Theme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'

import { Image } from 'components/common'
import { ClubType, EventType, UserType } from 'api/generated'
import Club from 'components/pages/user/ProfilePage/components/Club'
import Event from 'components/pages/user/ProfilePage/components/Event'
import { userPaths } from 'routes'
import { SearchField } from '../../inputs'
import { useSearch } from 'hooks/search'
import { Loader } from '../../layout'
import noAvatar from 'assets/images/event-no-image.jpg'

interface GlobalSearchWindowProps {
    onClose: () => void
}

const GlobalSearchWindow = ({ onClose }: GlobalSearchWindowProps) => {
    const lessThan1100px = useMediaQuery('(max-width:1100px)')

    const navigate = useNavigate()

    const [search, setSearch] = useState('')

    const { data, loading } = useSearch({ search })

    const renderPlate = (
        data: UserType | ClubType | EventType,
        type: 'club' | 'event' | 'user' = 'user'
    ) => {
        if (type === 'club') {
            return (
                <Club
                    key={data.id}
                    width={lessThan1100px ? '100%' : undefined}
                    club={data as ClubType}
                    onClick={() => {
                        onClose()
                        navigate(`../${userPaths.club(data.id as string)}`)
                    }}
                    type="created"
                />
            )
        }

        if (type === 'event') {
            return (
                <Event
                    key={data.id}
                    width={lessThan1100px ? '100%' : undefined}
                    event={data as EventType}
                    onClick={() => {
                        onClose()
                        navigate(`../${userPaths.event(data.id as string)}`)
                    }}
                    type="created"
                />
            )
        }

        return (
            <Stack
                direction="row"
                padding="12px"
                gap="12px"
                key={(data as UserType)?.id}
                sx={{
                    borderRadius: '5px',
                    border: '1px solid #e5e7eb',
                    transition: 'border 0.2s linear',
                    cursor: 'pointer',
                    width: lessThan1100px ? '100%' : '289px',
                    '&:hover': {
                        border: '1px solid #000',
                    },
                }}
                onClick={() => {
                    onClose()
                    navigate(`../${userPaths.profile}?id=${data.id as string}`)
                }}
            >
                <Image
                    src={
                        ((data as UserType)?.avatar?.img100x100 as string) ||
                        noAvatar
                    }
                    sx={{
                        height: '84px',
                        width: '117px',
                        objectFit: 'cover',
                        borderRadius: '9px',
                    }}
                />
                <Stack gap="4px" alignItems="flex-start">
                    <Typography
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="18px"
                    >
                        {`${(data as UserType)?.firstName} ${
                            (data as UserType)?.lastName
                        }`}
                    </Typography>
                    <Button
                        sx={(theme: Theme) => ({
                            justifyContent: 'flex-start',
                            padding: 0,
                            borderBottom: `1px solid ${theme.colors.primary}`,
                            minWidth: '0',
                            lineHeight: '17px',
                            borderRadius: 0,
                            fontSize: '13px',
                        })}
                        variant="text"
                    >
                        View
                    </Button>
                </Stack>
            </Stack>
        )
    }

    const renderPlaceholder = (text: string) => {
        return (
            <Stack alignItems="center" justifyContent="center" height="100%">
                <Typography color="#9c9c9c">{text}</Typography>
            </Stack>
        )
    }

    const renderLoader = () => {
        return <Loader />
    }

    const hasFoundItems =
        data?.users.length > 0 ||
        data?.clubs.length > 0 ||
        data?.events.length > 0

    return (
        <Stack
            gap="25px"
            minWidth={lessThan1100px ? '550px' : '883px'}
            minHeight="300px"
            position="relative"
            paddingTop="5px"
        >
            <SearchField
                customWidth="100%"
                autoFocus
                onChange={str => {
                    setSearch(str)
                }}
                placeholder="Search: Clubs, Events, Users"
            />
            {data ? (
                loading ? (
                    renderLoader()
                ) : hasFoundItems ? (
                    <Stack gap="25px" paddingBottom="30px">
                        {data.clubs.length > 0 && (
                            <Stack gap="20px">
                                <Typography
                                    fontSize="17px"
                                    lineHeight="19px"
                                    fontWeight={500}
                                    color="#1D1E20"
                                >
                                    Clubs
                                </Typography>
                                <Stack
                                    direction={
                                        lessThan1100px ? 'column' : 'row'
                                    }
                                    gap="8px"
                                >
                                    {data.clubs.map(c =>
                                        renderPlate(c as ClubType, 'club')
                                    )}
                                </Stack>
                                <Button
                                    sx={{
                                        alignSelf: 'flex-start',
                                    }}
                                    onClick={() => {
                                        onClose()
                                        navigate(`../${userPaths.clubs}`, {
                                            state: {
                                                search,
                                            },
                                        })
                                    }}
                                >
                                    View All Results
                                </Button>
                            </Stack>
                        )}
                        {data.events.length > 0 && (
                            <Stack gap="15px">
                                <Typography
                                    fontSize="17px"
                                    lineHeight="19px"
                                    fontWeight={500}
                                    color="#1D1E20"
                                >
                                    Events
                                </Typography>
                                <Stack
                                    direction={
                                        lessThan1100px ? 'column' : 'row'
                                    }
                                    gap="8px"
                                >
                                    {data.events.map(e =>
                                        renderPlate(e as EventType, 'event')
                                    )}
                                </Stack>
                                <Button
                                    sx={{
                                        alignSelf: 'flex-start',
                                    }}
                                    onClick={() => {
                                        onClose()
                                        navigate(`../${userPaths.events}`, {
                                            state: {
                                                search,
                                            },
                                        })
                                    }}
                                >
                                    View All Results
                                </Button>
                            </Stack>
                        )}

                        {data.users.length > 0 && (
                            <Stack gap="15px">
                                <Typography
                                    fontSize="17px"
                                    lineHeight="19px"
                                    fontWeight={500}
                                    color="#1D1E20"
                                >
                                    Users
                                </Typography>
                                <Stack
                                    direction={
                                        lessThan1100px ? 'column' : 'row'
                                    }
                                    gap="8px"
                                >
                                    {data.users.map(u =>
                                        renderPlate(u as UserType, 'user')
                                    )}
                                </Stack>
                                <Button
                                    sx={{
                                        alignSelf: 'flex-start',
                                    }}
                                    onClick={() => {
                                        onClose()
                                        navigate(`../${userPaths.users}`, {
                                            state: {
                                                search,
                                            },
                                        })
                                    }}
                                >
                                    View All Results
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                ) : (
                    renderPlaceholder(
                        `There are no items with "${search}" match`
                    )
                )
            ) : (
                renderPlaceholder('Type something to start searching')
            )}
        </Stack>
    )
}

export default GlobalSearchWindow
