import { object, string } from 'yup'

import { emailValidator, PASSWORD_REGEX } from '../index.validators'

export const SignUpFormInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password1: '',
    password2: '',
}

export const SignUpFormScheme = object({
    firstName: string()
        .max(32, 'Name should be less than 32 characters')
        .matches(/^[a-z ,'-]+$/i, 'Invalid characters')
        .required('First name is required'),
    lastName: string()
        .max(32, 'Name should be less than 32 characters')
        .matches(/^[a-z ,'-]+$/i, 'Invalid characters')
        .required('First name is required'),
    email: string()
        .max(32, 'Email should be less than 32 characters')
        .test(emailValidator)
        .required('Enter a valid email address'),
    password1: string()
        .matches(
            PASSWORD_REGEX,
            'Password should contain uppercase and lowercase letters, numbers and special symbols(@$!%*?&) and have 8-32 length'
        )
        .required('Enter password'),
    password2: string()
        .test('password_same', 'Passwords doesn’t match', function (value) {
            const { password1 } = this.parent

            return password1 === value
        })
        .required('Repeat password'),
})
