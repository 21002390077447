import React, { useState } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { InView } from 'react-intersection-observer'

import banner from 'assets/images/banner.png'
import { useHeaderHeight } from 'hooks/common'

const ReactInView = styled(InView)``
const BrowserButton = styled('button')``

interface BannerProps {
    onSignUp: () => void
}

const Banner = ({ onSignUp }: BannerProps) => {
    const headerHeight = useHeaderHeight()

    const [isImageInView, setImageInView] = useState(false)

    return (
        <Box>
            <ReactInView
                style={{
                    minHeight: `calc(100vh - ${headerHeight}px)`,
                    ...(isImageInView && { opacity: 1 }),
                }}
                onChange={inView => setImageInView(inView)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundImage: `url(${banner})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    opacity: 0,
                    transition: '.6s ease opacity',
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1142px',
                        width: '100%',
                        padding: '0 16px',
                        margin: 'auto',
                        boxSizing: 'border-box',
                    }}
                >
                    <Stack
                        alignItems="center"
                        gap="24px"
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            borderRadius: '6px',
                            padding: '16px',
                            boxSizing: 'border-box',
                            '@media (min-width: 1024px)': {
                                padding: '50px 16px',
                            },
                        }}
                    >
                        <Typography
                            textAlign="center"
                            variant="h1"
                            sx={{
                                fontSize: '28px',
                                lineHeight: '42px',
                                fontWeight: 700,
                            }}
                        >
                            Fostering genuine connections throughout school
                            communities.
                        </Typography>
                        <BrowserButton
                            onClick={onSignUp}
                            sx={{
                                outline: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                display: 'flex',
                                padding: '12px 16px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                borderRadius: '24px',
                                background: '#3fa4cf',
                                color: '#FDFDFD',
                                textAlign: 'center',
                                width: 'fit-content',
                                fontSize: '26px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '24px',
                                transition: '0.3s ease all',
                                boxSizing: 'border-box',
                                textWrap: 'no-wrap',
                                '&:hover': {
                                    backgroundColor: '#0077C2',
                                    cursor: 'pointer',
                                },
                                '&:active': {
                                    backgroundColor: '#005BA3',
                                },
                            }}
                        >
                            Join Now!
                        </BrowserButton>
                    </Stack>
                </Box>
            </ReactInView>
        </Box>
    )
}

export default Banner
