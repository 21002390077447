import React, { ReactNode } from 'react'

import { Stack } from '@mui/material'
import { SxProps, useMediaQuery } from '@mui/system'
import { Theme } from '@mui/material/styles'

interface PageLayoutProps {
    children?: JSX.Element | JSX.Element[] | ReactNode
    fullWidth?: boolean
    sx?: SxProps<Theme>
}

const PageLayout = ({
    children,
    fullWidth = false,
    sx = {},
}: PageLayoutProps) => {
    const lessThan500px = useMediaQuery('(max-width:500px)')

    return (
        <Stack
            sx={{
                position: 'relative',
                ...(fullWidth
                    ? {
                          padding: '44px 24px 24px',
                          ...(lessThan500px && {
                              padding: '22px 12px 12px',
                          }),
                      }
                    : {
                          padding: '40px 141px',
                          '@media (max-width:1100px)': {
                              padding: '20px 60px',
                          },
                          '@media (max-width:700px)': {
                              padding: '20px 40px',
                          },
                          '@media (max-width:500px)': {
                              padding: '20px',
                          },
                      }),
                ...sx,
            }}
        >
            {children}
        </Stack>
    )
}

export default PageLayout
