import isEmail from 'validator/lib/isEmail'
import validator from 'validator'

export const emailValidator = {
    name: 'email',
    message: 'Enter a valid email address',
    test: (value = ''): boolean => isEmail(value),
}
export const PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@+$!%):"|`(#^*_?&])[A-Za-z\d@+$!%):"|`(#^*_?&]{8,32}$/

export const isValidPostalCodeLocale = (
    locale: validator.PostalCodeLocale
): boolean => {
    return validator.isPostalCodeLocales.includes(locale)
}
