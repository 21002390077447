import React from 'react'

import { createTheme, alpha } from '@mui/material/styles'

const white = '#FFFFFF'
const background = '#F9FAFB'
const paperColor = '#fff'

const primary = {
    primary: '#1C64F2',
    primary99: '#FBFCFF',
    primary98: '#F5FAFF',
    primary95: '#E2F3FF',
    primary90: '#C1E8FF',
    primary80: '#75D1FF',
    primary70: '#24B8EF',
    primary60: '#009CCD',
    primary50: '#0080AA',
    primary40: '#006688',
    primary30: '#004D67',
    primary20: '#003548',
    primary10: '#001E2B',
    primary11: '#001E2B',
}

const secondary = {
    secondary: '#032080',
    secondary99: '#FEFBFF',
    secondary95: '#F0EFFF',
    secondary90: '#DEE1FF',
    secondary80: '#B9C3FF',
    secondary70: '#95A6FF',
    secondary60: '#778AEA',
    secondary50: '#5D70CE',
    secondary40: '#4357B3',
    secondary30: '#293E9A',
    secondary20: '#0A2483',
    secondary10: '#001258',
}
const neutral = {
    neutral: '#74747B',
    neutral99: '#FBFCFE',
    neutral98: '#F8F9FC',
    neutral95: '#F0F1F3',
    neutral90: '#E1E2E5',
    neutral80: '#C5C6C9',
    neutral70: '#AAABAD',
    neutral60: '#8F9193',
    neutral50: '#757779',
    neutral40: '#5C5F61',
    neutral30: '#454749',
    neutral20: '#2E3133',
    neutral10: '#191C1E',
}

const neutralVariant = {
    neutralVariant: '#6B7280',
    neutralVariant99: '#FBFCFF',
    neutralVariant95: '#EBF2F8',
    neutralVariant90: '#DCE3E9',
    neutralVariant80: '#C0C7CD',
    neutralVariant70: '#A5ACB2',
    neutralVariant60: '#8A9297',
    neutralVariant50: '#71787D',
    neutralVariant40: '#585F64',
    neutralVariant30: '#40484D',
    neutralVariant20: '#2A3136',
    neutralVariant10: '#151D21',
}

const error = {
    error: '#C81E1E',
    error99: '#FFFBFF',
    error98: '#FFF8F7',
    error95: '#FFEDEA',
    error90: '#FFDAD6',
    error80: '#FFB4AB',
    error70: '#FF897D',
    error60: '#FF5449',
    error50: '#DE3730',
    error40: '#BA1A1A',
    error30: '#93000A',
    error20: '#690005',
    error10: '#410002',
}
const warning = {
    warning: '#FFBB00',
    warning99: '#FFFBFF',
    warning95: '#FFEFD6',
    warning90: '#FFDEA4',
    warning80: '#FFBB00',
    warning70: '#DCA100',
    warning60: '#BB8800',
    warning50: '#9A7000',
    warning40: '#7B5800',
    warning30: '#5D4200',
    warning20: '#412D00',
    warning10: '#261900',
}
const success = {
    success: '#5C9F52',
    success99: '#F7FFEF',
    success95: '#CAFFBB',
    success90: '#ACF59D',
    success80: '#91D884',
    success70: '#77BC6B',
    success60: '#5DA053',
    success50: '#44853C',
    success40: '#2A6C25',
    success30: '#0D530E',
    success20: '#003A03',
    success10: '#002201',
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        tiny24: React.CSSProperties
        regular8: React.CSSProperties
        regular10: React.CSSProperties
        regular12: React.CSSProperties
        regular14: React.CSSProperties
        regular16: React.CSSProperties
        regular18: React.CSSProperties
        medium10: React.CSSProperties
        medium12: React.CSSProperties
        medium14: React.CSSProperties
        medium16: React.CSSProperties
        medium18: React.CSSProperties
        medium20: React.CSSProperties
        medium24: React.CSSProperties
        bold10: React.CSSProperties
        bold12: React.CSSProperties
        bold14: React.CSSProperties
        bold16: React.CSSProperties
        bold18: React.CSSProperties
        bold20: React.CSSProperties
        bold24: React.CSSProperties
    }

    interface TypographyVariantsOptions {
        tiny24: React.CSSProperties
        regular8: React.CSSProperties
        regular10: React.CSSProperties
        regular12: React.CSSProperties
        regular14: React.CSSProperties
        regular16: React.CSSProperties
        regular18: React.CSSProperties
        medium10: React.CSSProperties
        medium12: React.CSSProperties
        medium14: React.CSSProperties
        medium16: React.CSSProperties
        medium18: React.CSSProperties
        medium20: React.CSSProperties
        medium24: React.CSSProperties
        bold10: React.CSSProperties
        bold12: React.CSSProperties
        bold14: React.CSSProperties
        bold16: React.CSSProperties
        bold18: React.CSSProperties
        bold20: React.CSSProperties
        bold24: React.CSSProperties
    }

    interface Theme {
        colors: {
            primary: React.CSSProperties['color']
            primary99: React.CSSProperties['color']
            primary98: React.CSSProperties['color']
            primary95: React.CSSProperties['color']
            primary90: React.CSSProperties['color']
            primary80: React.CSSProperties['color']
            primary70: React.CSSProperties['color']
            primary60: React.CSSProperties['color']
            primary50: React.CSSProperties['color']
            primary40: React.CSSProperties['color']
            primary30: React.CSSProperties['color']
            primary20: React.CSSProperties['color']
            primary10: React.CSSProperties['color']
            secondary: React.CSSProperties['color']
            secondary99: React.CSSProperties['color']
            secondary95: React.CSSProperties['color']
            secondary90: React.CSSProperties['color']
            secondary80: React.CSSProperties['color']
            secondary70: React.CSSProperties['color']
            secondary60: React.CSSProperties['color']
            secondary50: React.CSSProperties['color']
            secondary40: React.CSSProperties['color']
            secondary30: React.CSSProperties['color']
            secondary20: React.CSSProperties['color']
            secondary10: React.CSSProperties['color']
            neutral: React.CSSProperties['color']
            neutral99: React.CSSProperties['color']
            neutral98: React.CSSProperties['color']
            neutral95: React.CSSProperties['color']
            neutral90: React.CSSProperties['color']
            neutral80: React.CSSProperties['color']
            neutral70: React.CSSProperties['color']
            neutral60: React.CSSProperties['color']
            neutral50: React.CSSProperties['color']
            neutral40: React.CSSProperties['color']
            neutral30: React.CSSProperties['color']
            neutral20: React.CSSProperties['color']
            neutral10: React.CSSProperties['color']
            neutralVariant: React.CSSProperties['color']
            neutralVariant99: React.CSSProperties['color']
            neutralVariant95: React.CSSProperties['color']
            neutralVariant90: React.CSSProperties['color']
            neutralVariant80: React.CSSProperties['color']
            neutralVariant70: React.CSSProperties['color']
            neutralVariant60: React.CSSProperties['color']
            neutralVariant50: React.CSSProperties['color']
            neutralVariant40: React.CSSProperties['color']
            neutralVariant30: React.CSSProperties['color']
            neutralVariant20: React.CSSProperties['color']
            neutralVariant10: React.CSSProperties['color']
            error: React.CSSProperties['color']
            error99: React.CSSProperties['color']
            error98: React.CSSProperties['color']
            error95: React.CSSProperties['color']
            error90: React.CSSProperties['color']
            error80: React.CSSProperties['color']
            error70: React.CSSProperties['color']
            error60: React.CSSProperties['color']
            error50: React.CSSProperties['color']
            error40: React.CSSProperties['color']
            error30: React.CSSProperties['color']
            error20: React.CSSProperties['color']
            error10: React.CSSProperties['color']
            warning: React.CSSProperties['color']
            warning99: React.CSSProperties['color']
            warning95: React.CSSProperties['color']
            warning90: React.CSSProperties['color']
            warning80: React.CSSProperties['color']
            warning70: React.CSSProperties['color']
            warning60: React.CSSProperties['color']
            warning50: React.CSSProperties['color']
            warning40: React.CSSProperties['color']
            warning30: React.CSSProperties['color']
            warning20: React.CSSProperties['color']
            warning10: React.CSSProperties['color']
            success: React.CSSProperties['color']
            success99: React.CSSProperties['color']
            success95: React.CSSProperties['color']
            success90: React.CSSProperties['color']
            success80: React.CSSProperties['color']
            success70: React.CSSProperties['color']
            success60: React.CSSProperties['color']
            success50: React.CSSProperties['color']
            success40: React.CSSProperties['color']
            success30: React.CSSProperties['color']
            success20: React.CSSProperties['color']
            success10: React.CSSProperties['color']
            white: React.CSSProperties['color']
            background: React.CSSProperties['color']
            paperColor: React.CSSProperties['color']
        }
    }

    interface ThemeOptions {
        colors: {
            primary: React.CSSProperties['color']
            secondary: React.CSSProperties['color']
            neutral: React.CSSProperties['color']
            neutralVariant: React.CSSProperties['color']
            error: React.CSSProperties['color']
            warning: React.CSSProperties['color']
            success: React.CSSProperties['color']
            white: React.CSSProperties['color']
            background: React.CSSProperties['color']
            paperColor: React.CSSProperties['color']
        }
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        tiny24: true
        regular8: true
        regular10: true
        regular12: true
        regular14: true
        regular16: true
        regular18: true
        medium10: true
        medium12: true
        medium14: true
        medium16: true
        medium18: true
        medium20: true
        medium24: true
        bold10: true
        bold12: true
        bold14: true
        bold16: true
        bold18: true
        bold20: true
        bold24: true
    }
}

export const theme = createTheme({
    palette: {
        primary: { main: primary.primary },
        secondary: { main: secondary.secondary },
        warning: { main: warning.warning },
        error: { main: error.error },
        success: { main: success.success },
    },
    colors: {
        ...primary,
        ...secondary,
        ...warning,
        ...error,
        ...success,
        ...neutral,
        ...neutralVariant,
        white,
        background,
        paperColor,
    },
    typography: {
        fontFamily: 'Inter',
        tiny24: {
            fontWeight: 300,
            fontSize: '24px',
            lineHeight: '32px',
        },
        regular8: {
            fontWeight: 400,
            fontSize: '8px',
            lineHeight: '10px',
        },
        regular10: {
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '14px',
        },
        regular12: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
        },
        regular14: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
        },
        regular16: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '22px',
        },
        regular18: {
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '24px',
        },
        medium10: {
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '14px',
        },
        medium12: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
        },
        medium14: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
        },
        medium16: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '22px',
        },
        medium18: {
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '24px',
        },
        medium20: {
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '28px',
        },
        medium24: {
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '32px',
        },
        bold10: {
            fontWeight: 700,
            fontSize: '10px',
            lineHeight: '14px',
        },
        bold12: {
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '16px',
        },
        bold14: {
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '20px',
        },
        bold16: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '20px',
        },
        bold18: {
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '24px',
        },
        bold20: {
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '28px',
        },
        bold24: {
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '32px',
        },
    },
})

export const appTheme = createTheme(theme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    textTransform: 'capitalize',
                    borderRadius: '6px',
                },
                textPrimary: {
                    color: theme.colors.primary,
                },
            },
            defaultProps: {
                variant: 'contained',
                disableRipple: true,
                disableFocusRipple: true,
                disableElevation: true,
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    textDecoration: 'none',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    margin: 0,
                    gap: '10px',
                    '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                        fontWeight: '500',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    gap: '8px',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '& .MuiPaper-root': {
                        '@media (max-width: 700px)': {
                            width: '100%',
                        },
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    position: 'relative !important',
                    color: theme.colors.neutral,
                    fontWeight: '500',
                    letterSpacing: '0.1px',
                    fontSize: '12px',
                    lineHeight: '15px',
                    transform: 'none !important',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    margin: 0,
                    fontWeight: '400',
                    letterSpacing: '0.1px',
                    lineHeight: '15px',
                    fontSize: '12px',
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 42,
                    height: 26,
                    padding: 0,
                    '& .MuiSwitch-switchBase': {
                        padding: 0,
                        margin: 2,
                        transitionDuration: '300ms',
                        '&.Mui-checked': {
                            transform: 'translateX(16px)',
                            color: '#fff',
                            '& + .MuiSwitch-track': {
                                backgroundColor: theme.colors.primary,
                                opacity: 1,
                                border: 0,
                            },
                            '&.Mui-disabled + .MuiSwitch-track': {
                                opacity: 0.5,
                            },
                        },
                        '&.Mui-focusVisible .MuiSwitch-thumb': {
                            color: theme.colors.primary,
                            border: '6px solid #fff',
                        },
                        '&.Mui-disabled .MuiSwitch-thumb': {
                            color:
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[600],
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        boxSizing: 'border-box',
                        width: 22,
                        height: 22,
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 26 / 2,
                        backgroundColor:
                            theme.palette.mode === 'light'
                                ? '#E9E9EA'
                                : '#39393D',
                        opacity: 1,
                        transition: theme.transitions.create(
                            ['background-color'],
                            {
                                duration: 500,
                            }
                        ),
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {},
                indicator: {
                    backgroundColor: 'red',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '15px 4px',
                    minWidth: '0',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        '& ~ .MuiFormHelperText-root': {
                            color: theme.colors.error,
                        },
                        '& .MuiInputBase-input': {
                            borderColor: theme.colors.error,
                        },
                    },
                    '& .MuiInputBase-input': {
                        borderRadius: 5,
                        position: 'relative',
                        background: '#fff',
                        border: '1.25px solid',
                        borderColor: '#E0E3E7',
                        fontSize: 15,
                        padding: '10px 12px',
                        transition: theme.transitions.create([
                            'border-color',
                            'background-color',
                            'box-shadow',
                        ]),
                        '&:focus': {
                            boxShadow: `${alpha(
                                theme.palette.primary.main,
                                0.25
                            )} 0 0 0 0.2rem`,
                            borderColor: theme.palette.primary.main,
                        },
                    },
                },
            },
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-listbox': {
                        padding: '20px 24px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        '@media (max-width: 600px)': {
                            padding: '12px 14px',
                            gap: '4px',
                        },
                        '& .MuiListItem-root': {
                            '&:hover, &.Mui-focused': {
                                color: theme.colors.primary,
                                '& .MuiSvgIcon-root': {
                                    color: theme.colors.primary,
                                },
                            },
                            '& .MuiListItemIcon-root': {
                                minWidth: 0,
                            },
                        },
                        '& .MuiAutocomplete-option': {
                            padding: 0,
                            gap: '8px',
                            fontSize: '13px',
                            lineHeight: '16px',
                            color: '#1D1E20',
                            alignItems: 'center',
                            overflow: 'visible',
                            '@media (max-width: 600px)': {
                                minHeight: '26px',
                            },
                            '& .MuiCheckbox-root': {
                                padding: 0,
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                            '&[aria-selected="true"], &.Mui-focused, &[aria-selected="true"].Mui-focused':
                                {
                                    backgroundColor: 'transparent',
                                },
                            '&[data-select-all="true"]': {
                                paddingBottom: '16px',
                                borderBottom: '1px solid #E4E4EB',
                            },
                        },
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    '& .MuiMenu-paper': {
                        marginTop: '8px',
                        borderRadius: '5px',
                        border: '1px solid #E4E4EB',
                        boxShadow: '0px 4px 16px 0px rgba(28, 38, 83, 0.08)',
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-inputRoot': {
                        paddingRight: '0 !important',
                    },
                    '& .MuiAutocomplete-endAdornment': {
                        right: '10px',
                    },
                    '& .MuiFormLabel-root': {
                        '&.Mui-error': {
                            color: '#74747B',
                        },
                    },
                    '& .MuiAutocomplete-listbox': {
                        padding: '20px 24px',
                    },
                    '& .MuiTextField-root': {
                        fontSize: 15,
                        borderRadius: '5px',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none !important',
                        },
                        '& .MuiInputBase-root': {
                            background: '#fff',
                            border: '1.25px solid',
                            borderColor: '#E0E3E7',
                            padding: '3.5px 0 1.5px 12px',
                            '&.Mui-error': {
                                borderColor: theme.colors.error,
                            },
                        },
                        '& .MuiInputBase-input': {
                            padding: '7.5px 0 7.5px 0 !important',
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            border: 'none !important',
                            '&:focus': {
                                boxShadow: `none !important`,
                                borderColor: 'none !important',
                            },
                        },
                        '& .MuiChip-root': {
                            margin: '2px',
                        },
                    },
                },
                inputRoot: {},
            },
        },
    },
})
