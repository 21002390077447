import React from 'react'

import { LinearProgress, Stack, Typography } from '@mui/material'
import { useMediaQuery } from '@mui/system'
import { NotificationsNone } from '@mui/icons-material'

import { NotificationType } from 'api/generated'
import { Notification } from 'components/common'
import {
    useNotificationAPI,
    useNotifications,
} from '../../../hooks/notification'

interface NotificationsLayoutProps {
    onClose: () => void
}

const NotificationsLayout = ({ onClose }: NotificationsLayoutProps) => {
    const { notifications, loading, refetch } = useNotifications({
        ordering: '-created_at',
        isRead: false,
    })
    const { markAsRead } = useNotificationAPI()

    const lessThan700px = useMediaQuery('(max-width:700px)')

    const renderEmptyList = () => {
        return (
            <Stack
                justifyContent="space-between"
                alignItems="center"
                padding="50px 0"
                gap="5px"
                data-test-id="no-notifications"
            >
                <NotificationsNone
                    sx={{
                        fontSize: '38px',
                        color: '#9d9d9d',
                    }}
                />
                <Typography color="#74747B">
                    You have no notifications yet
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack
            padding="24px"
            gap="12px"
            minWidth={lessThan700px ? '0' : '650px'}
        >
            {notifications.length === 0
                ? renderEmptyList()
                : notifications
                      .filter(n => !n.isRead)
                      .map(item => (
                          <Notification
                              data={item}
                              listed
                              onClose={onClose}
                              onCloseNotification={(id: string) => {
                                  markAsRead({
                                      input: {
                                          ids: [id],
                                      },
                                  }).then(response => {
                                      if (response?.success) {
                                          refetch()
                                      }
                                  })
                              }}
                          />
                      ))}
        </Stack>
    )
}

export default NotificationsLayout
