import React, { useState } from 'react'

import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { InView } from 'react-intersection-observer'

import img1 from 'assets/images/communication.png'
import img3 from 'assets/images/party.png'
import img4 from 'assets/images/students.png'
import img2 from 'assets/images/logo-small-blue.png'

const ReactInView = styled(InView)``
const BrowserList = styled('ul')``
const BrowserListItem = styled('li')``
const BrowserImage = styled('img')``

export const ecosystemListData: {
    image: string
    description: string
    id: number
}[] = [
    {
        id: 1,
        image: img1,
        description:
            'KoolYard, an innovative communication and networking platform, is designed to facilitate genuine connections among high school students. Enabling peers to easily engage with various teams, clubs, and the broader school community, our platform serves as a hub to organize and promote extracurricular activities and events.',
    },
    {
        id: 2,
        image: img2,
        description:
            'At KoolYard, we prioritize creating a safe and inspiring environment where students can explore their interests while fostering mutual education and support.',
    },
    {
        id: 3,
        image: img3,
        description:
            'Developed for students by students. KoolYard was initiated by a team of Swiss university students. This summer, KoolYard was further developed in collaboration with sudents from esteemed institutions, including MIT, Northeastern, the University of Florida, and Stanford and high school students from Boston, Houston, and the Bay Area.',
    },
    {
        id: 4,
        image: img4,
        description:
            'With continuous input from college and high school students across the country, we remain committed to upgrading Koolyard and inviting all high school students nationwide in the Fall of 2025.',
    },
]

const AboutUs = () => {
    const [isImageInView, setImageInView] = useState(false)

    return (
        <Box
            id="about"
            sx={{
                padding: '56px 0',
                '@media (min-width: 651px)': { padding: '160px 0' },
            }}
        >
            <ReactInView
                onChange={inView => setImageInView(inView)}
                sx={{
                    maxWidth: '1142px',
                    width: '100%',
                    padding: '0 16px',
                    margin: 'auto',
                    boxSizing: 'border-box',
                    opacity: 0,
                    transition: '1.4s ease opacity',
                    ...(isImageInView && { opacity: 1 }),
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        marginBottom: '56px',
                        textAlign: 'center',
                        color: '#000',
                        letterSpacing: '0.88px',
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: '34px',
                        '@media (min-width: 651px)': {
                            fontSize: '44px',
                            fontWeight: 600,
                            lineHeight: '66px',
                        },
                    }}
                >
                    ABOUT US
                </Typography>

                <BrowserList
                    sx={{
                        margin: 0,
                        padding: 0,
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        columnGap: '30px',
                        rowGap: '50px',
                        '@media (min-width: 951px)': {
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            rowGap: '80px',
                        },
                    }}
                >
                    {ecosystemListData.map(item => (
                        <BrowserListItem
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'center',
                                gap: '32px',
                                flex: 1,
                                '@media (min-width: 951px)': {
                                    '&:nth-child(odd)': {
                                        flexDirection: 'column-reverse',
                                    },
                                },
                            }}
                            key={item.id}
                        >
                            <Box
                                sx={{
                                    borderRadius: '4px',
                                    background: '#FFF',
                                    width: 'fit-content',
                                    margin: '0 auto',
                                    padding: '12px',
                                    boxShadow:
                                        '0px 2px 8px 0px rgba(0, 0, 0, 0.12)',
                                }}
                            >
                                <BrowserImage
                                    sx={{ height: '150px', width: 'auto' }}
                                    src={item.image}
                                    alt=""
                                />
                            </Box>

                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        lineHeight: '28px',
                                        padding: '0 10px',
                                    }}
                                >
                                    {item.description}
                                </Typography>
                            </Box>
                        </BrowserListItem>
                    ))}
                </BrowserList>
            </ReactInView>
        </Box>
    )
}

export default AboutUs
