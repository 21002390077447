export interface Crop {
    x: number
    y: number
    width: number
    height: number
}

export interface CroppedData {
    preview: string
    file: Blob
}

export const createImage = (url: string) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossorigin', 'anonymous')
        image.src = url
    })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
    imageSrc: string,
    pixelCrop: Crop,
    flip = { horizontal: false, vertical: false }
) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    // set canvas size to match the bounding box
    canvas.width = (image as HTMLImageElement).width
    canvas.height = (image as HTMLImageElement).height

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(
        (image as HTMLImageElement).width / 2,
        (image as HTMLImageElement).height / 2
    )
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(
        -(image as HTMLImageElement).width / 2,
        -(image as HTMLImageElement).height / 2
    )

    // draw rotated image
    ctx.drawImage(image as HTMLImageElement, 0, 0)

    const croppedCanvas = document.createElement('canvas')

    const croppedCtx = croppedCanvas.getContext('2d')

    if (!croppedCtx) {
        return null
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width
    croppedCanvas.height = pixelCrop.height

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    )

    // As a blob
    return new Promise(resolve => {
        croppedCanvas.toBlob(file => {
            resolve({
                preview: URL.createObjectURL(file as Blob),
                file,
            })
        }, 'image/png')
    })
}
