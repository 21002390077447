import React from 'react'

import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client'

import { DrawerProps } from 'components/common/Drawer'

export interface ReactiveDrawer<U> {
    component?: React.FC<U>
    props?: U
    options?: DrawerProps
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DrawerReactiveVar: ReactiveVar<ReactiveDrawer<any>> = makeVar({})

interface UseDrawerResult {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    open: ({ component, props, options }: ReactiveDrawer<any>) => void
    close: () => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: ReactiveDrawer<any>
}
const useDrawer = (): UseDrawerResult => {
    const data = useReactiveVar(DrawerReactiveVar)
    const close = (): void => {
        DrawerReactiveVar({})
    }

    const open = ({
        component,
        props: newProps,
        options: newOptions,
    }: ReactiveDrawer<unknown>): void => {
        DrawerReactiveVar({ component, props: newProps, options: newOptions })
    }

    return { open, close, data }
}

export default useDrawer
