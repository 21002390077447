import React, { Suspense } from 'react'

import { Route as RouteComponent, Routes, Navigate } from 'react-router-dom'

import { Loader } from 'components/layout'
import { KeyValType, Maybe } from 'api/generated'

export const renderRoutesByRole = (routes: any[], role?: Maybe<KeyValType>) => {
    if (role) {
        const allowedRoutes = role
            ? routes.filter(route =>
                  route.restrict ? route.restrict?.includes(role.val) : true
              )
            : []

        return (
            <Suspense fallback={<Loader />}>
                <Routes>
                    {allowedRoutes.map(r => (
                        <RouteComponent
                            key={r.name}
                            path={r.path}
                            element={r.element}
                            index={r.index}
                        />
                    ))}
                </Routes>
            </Suspense>
        )
    }

    return null
}
