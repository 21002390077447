import React from 'react'

import {
    Dialog as MuiDialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    SxProps,
    Typography,
} from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import { useMediaQuery } from '@mui/system'

import DialogButtons from './Buttons'

export interface DialogProps {
    title?: string
    open?: boolean
    onClose: () => void
    onSubmit?: () => void
    children?: JSX.Element
    sx?: SxProps
}

const Dialog = (props: DialogProps): JSX.Element => {
    const { children, sx, title, onSubmit, onClose, open = true } = props
    const lessThan700px = useMediaQuery('(max-width:700px)')

    return (
        <MuiDialog sx={sx} maxWidth={false} open={open} onClose={onClose}>
            {title && (
                <DialogTitle
                    sx={{
                        fontFamily: 'Roboto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '5px',
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography fontWeight={500}>{title}</Typography>
                    </Stack>
                    <IconButton onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent
                sx={{
                    display: 'flex',
                    minWidth: lessThan700px ? '0' : '450px',
                }}
            >
                {children}
            </DialogContent>
            {onSubmit && (
                <DialogButtons
                    removePaddings={false}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                />
            )}
        </MuiDialog>
    )
}

export default Dialog
