import React from 'react'

import { useFormik } from 'formik'
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    Stack,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import { Form } from 'components/common'
import { CreatePasswordFormScheme } from './index.schema'
import { useAuth } from 'hooks/auth'
import { Loader } from 'components/layout'
import { authPaths } from 'routes/index'
import { PasswordField } from '../../inputs'

export const CreatePasswordInitialValues = {
    newPassword1: '',
    newPassword2: '',
}

interface ResetPasswordFormProps {
    uid: string
    token: string
}

const ResetPasswordForm = ({
    uid,
    token,
}: ResetPasswordFormProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar()
    const { resetPasswordConfirm, isLoading } = useAuth()
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: CreatePasswordInitialValues,
        validationSchema: CreatePasswordFormScheme,
        onSubmit: values => {
            resetPasswordConfirm({ ...values, uid, token })
                .then(response => {
                    if (response?.success) {
                        enqueueSnackbar('Password changed')
                        navigate(`../${authPaths.login}`)
                    } else {
                        enqueueSnackbar('Error happened')
                    }
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' })
                })
        },
    })

    const isSubmitDisabled =
        (Object.keys(formik.touched).length > 1 &&
            Object.keys(formik.errors).length > 1) ||
        isLoading

    return (
        <Box>
            {isLoading && <Loader />}
            <Form
                onSubmit={formik.handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    minWidth: '300px',
                }}
            >
                <Stack gap="24px">
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="newPassword1">
                            New Password
                        </InputLabel>
                        <PasswordField
                            value={formik.values.newPassword1}
                            onChange={formik.handleChange}
                            name="newPassword1"
                            error={
                                formik.touched.newPassword1 &&
                                Boolean(formik.errors.newPassword1)
                            }
                        />
                        {formik.touched.newPassword1 &&
                            formik.errors.newPassword1 && (
                                <FormHelperText>
                                    {formik.errors.newPassword1}
                                </FormHelperText>
                            )}
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="newPassword2">
                            Confirm Password
                        </InputLabel>
                        <PasswordField
                            value={formik.values.newPassword2}
                            onChange={formik.handleChange}
                            name="newPassword2"
                            error={
                                formik.touched.newPassword2 &&
                                Boolean(formik.errors.newPassword2)
                            }
                        />
                        {formik.touched.newPassword2 &&
                            formik.errors.newPassword2 && (
                                <FormHelperText>
                                    {formik.errors.newPassword2}
                                </FormHelperText>
                            )}
                    </FormControl>
                    <Button
                        sx={{
                            padding: '11px 16px',
                            fontSize: '15px',
                        }}
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={isSubmitDisabled}
                    >
                        Proceed
                    </Button>
                </Stack>
            </Form>
        </Box>
    )
}
export default ResetPasswordForm
