import { format } from 'date-fns'

import { AddressType, Maybe } from '../api/generated'

export const formatLocation = (address?: Maybe<Partial<AddressType>>) => {
    if (!address) {
        return '-'
    }

    const result = []

    if (address.building) {
        result.push(address.building)
    }

    if (address.street) {
        result.push(address.street)
    }

    if (address.city) {
        result.push(address.city)
    }

    if (address.state) {
        result.push(address.state)
    }

    if (address.zipCode) {
        result.push(address.zipCode)
    }

    return result.join(', ')
}

export const formatDate = (date: string) => {
    return format(new Date(date), 'LLL d, p')
}
