import React from 'react'

import { Box, Typography } from '@mui/material'

const BottomBar = () => {
    return (
        <Box
            sx={{
                padding: '32px 0 56px',
                '@media (min-width: 601px)': { padding: '40px 0 80px' },
            }}
        >
            <Box
                sx={{
                    maxWidth: '1142px',
                    width: '100%',
                    padding: '0 16px',
                    margin: 'auto',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    '@media (min-width: 601px)': {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    },
                }}
            >
                <Typography
                    sx={{
                        color: '#000',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '150%',
                    }}
                >
                    © 2024. All rights reserved. KoolYard.
                </Typography>
            </Box>
        </Box>
    )
}

export default BottomBar
