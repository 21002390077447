import React from 'react'

import { useNavigate } from 'react-router-dom'

import { PostSignUpForm } from 'components/forms'
import { AuthorizationLayout } from 'components/layout'
import { useSchools } from 'hooks/school'
import { userPaths } from '../../../../routes'

const PostSignUpPage = (): JSX.Element => {
    const { schools } = useSchools()
    const navigate = useNavigate()

    const onSuccess = (): void => {
        navigate(`../${userPaths.postSignUpExtra}`)
    }

    return (
        <AuthorizationLayout title="Welcome">
            <PostSignUpForm onSuccess={onSuccess} schools={schools} />
        </AuthorizationLayout>
    )
}

export default PostSignUpPage
