import React, { lazy } from 'react'

const AdminHomePage = lazy(
    () =>
        import(
            /* webpackChunkName: "AdminHomePage" */ 'components/pages/admin/AdminHomePage'
        )
)

const AdminAccountPage = lazy(
    () =>
        import(
            /* webpackChunkName: "AdminAccountPage" */ 'components/pages/admin/AdminAccountPage'
        )
)

const adminPaths = {
    homepage: '/',
    users: '/users',
    events: '/events',
    clubs: '/clubs',
    communications: '/communications',
}

const adminRoutes = [
    {
        path: adminPaths.homepage,
        element: <AdminHomePage />,
        name: 'homepage',
        restrict: ['Admin'],
    },
    {
        path: adminPaths.events,
        element: <AdminAccountPage />,
        name: 'account',
        restrict: ['Admin'],
    },
]

export { adminPaths, adminRoutes }
