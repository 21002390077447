import React, { useLayoutEffect } from 'react'

import { Divider, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import Header from '../LandingPage/components/Header'
import { authPaths } from '../../../../routes'

const BrowserList = styled('ul')``
const BrowserListItem = styled('li')``

const TermsPage = (): JSX.Element => {
    const navigate = useNavigate()

    const onSignUp = (): void => {
        navigate(`../${authPaths.signUp}`)
    }

    const onSignIn = (): void => {
        navigate(`../${authPaths.login}`)
    }

    const onAbout = (): void => {
        navigate(`../${authPaths.landing}#about`)
    }

    const onContacts = (): void => {
        navigate(`../${authPaths.landing}#footer`)
    }

    const onJoin = (): void => {
        navigate(`../${authPaths.landing}#join`)
    }

    useLayoutEffect(() => {
        // Scroll to the top of the page when the route changes
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    }, [])

    return (
        <Stack
            sx={{
                maxWidth: '1142px',
                width: '100%',
                padding: '0 16px 46px',
                margin: 'auto',
                boxSizing: 'border-box',
            }}
            gap="32px"
        >
            <Header
                onSignUp={onSignUp}
                onSignIn={onSignIn}
                onAbout={onAbout}
                onContacts={onContacts}
                onJoin={onJoin}
            />
            <Stack divider={<Divider />} gap="24px">
                <Stack gap="16px">
                    <Typography variant="h5" fontWeight="700">
                        Interpretation and Definitions
                    </Typography>
                    <Typography variant="h6" fontWeight="700">
                        Interpretation
                    </Typography>
                    <Typography variant="body1">
                        The words of which the initial letter is capitalized
                        have meanings defined under the following conditions.
                        The following definitions shall have the same meaning
                        regardless of whether they appear in singular or plural.
                    </Typography>
                    <Typography variant="h6" fontWeight="700">
                        Definitions
                    </Typography>
                    <Typography variant="body1">
                        For the purposes of these Terms and Conditions:
                    </Typography>
                    <BrowserList>
                        <BrowserListItem>
                            <b>Affiliate</b> means an entity that controls, is
                            controlled by, or is under common control with a
                            party, where &quot;control&quot; means ownership of
                            50% or more of the shares, equity interest, or other
                            securities entitled to vote for election of
                            directors or other managing authority.
                        </BrowserListItem>
                        <BrowserListItem>
                            <b>Country</b> refers to: United States.
                        </BrowserListItem>
                        <BrowserListItem>
                            <b>Device</b> means any device that can access the
                            Service, such as a computer, cellphone, or digital
                            tablet.
                        </BrowserListItem>

                        <BrowserListItem>
                            <b>Service</b> refers to the KoolYard platform.
                        </BrowserListItem>
                        <BrowserListItem>
                            <b>Terms and Conditions</b> (also referred to as
                            &quot;Terms&quot;) mean these Terms and Conditions
                            that form the entire agreement between You and the
                            Company regarding the use of the Service.
                        </BrowserListItem>
                        <BrowserListItem>
                            <b>Third-party Social Media Service</b> means any
                            services or content (including data, information,
                            products, or services) provided by a third-party
                            that may be displayed, included, or made available
                            by the Service.
                        </BrowserListItem>
                        <BrowserListItem>
                            <b>You</b> means the individual accessing or using
                            the Service, or the company or other legal entity on
                            behalf of which such individual is accessing or
                            using the Service, as applicable.
                        </BrowserListItem>
                    </BrowserList>
                </Stack>
                <Stack gap="16px">
                    <Typography variant="h5" fontWeight="700">
                        Acknowledgment
                    </Typography>
                    <Typography variant="body1">
                        These are the Terms and Conditions governing the use of
                        this Service and the agreement that operates between You
                        and the Company. These Terms set out the rights and
                        obligations of all users regarding the use of the
                        Service.
                    </Typography>
                    <Typography variant="body1">
                        Your access to and use of the Service is conditioned on
                        Your acceptance of and compliance with these Terms.
                        These Terms apply to all visitors, users, and others who
                        access or use the Service.
                    </Typography>
                    <Typography variant="body1">
                        By accessing or using the Service, You agree to be bound
                        by these Terms. If You disagree with any part of these
                        Terms, You may not access the Service.
                    </Typography>
                    <Typography variant="body1">
                        You represent that You are over the age of 13. The
                        Company does not permit those under 13 to use the
                        Service.
                    </Typography>
                    <Typography variant="body1">
                        Your access to and use of the Service is also
                        conditioned on Your acceptance of and compliance with
                        the Privacy Policy of the Company. Our Privacy Policy
                        describes Our policies and procedures on the collection,
                        use, and disclosure of Your personal information when
                        You use the KoolYard platform. Please read Our Privacy
                        Policy carefully before using Our Service.
                    </Typography>
                </Stack>
                <Stack gap="16px">
                    <Typography variant="h5" fontWeight="700">
                        Inappropriate Use and Termination
                    </Typography>
                    <Typography variant="body1">
                        We reserve the right to ban any User or suspend access
                        for violating the platform rules, which include but are
                        not limited to:
                    </Typography>
                    <BrowserList>
                        <BrowserListItem>
                            Engaging in bullying, harassment, or discrimination.
                        </BrowserListItem>
                        <BrowserListItem>
                            Posting explicit, offensive, or inappropriate
                            content.
                        </BrowserListItem>
                        <BrowserListItem>
                            Promoting illegal activities.
                        </BrowserListItem>
                        <BrowserListItem>
                            Hacking, disrupting, or causing harm to the platform
                            or other users.
                        </BrowserListItem>
                        <BrowserListItem>
                            Spamming or flooding the platform with irrelevant
                            content
                        </BrowserListItem>
                        <BrowserListItem>
                            Misuse of personal information or violation of other
                            users&apos; privacy.
                        </BrowserListItem>
                        <BrowserListItem>
                            Impersonation of others or misrepresentation.
                        </BrowserListItem>
                    </BrowserList>
                    <Typography variant="body1">
                        Banned Users will lose all rights to their accounts,
                        Clubs, or Events. Upon termination, Your right to use
                        the Service will cease immediately.
                    </Typography>
                    <Typography variant="body1">
                        If You believe Your account, Club, or Event was
                        wrongfully terminated or suspended, You may appeal the
                        decision by contacting our support team at{'  '}
                        <b>support@koolyard.com</b>. We will review the appeal
                        and respond with a decision within a reasonable
                        timeframe.
                    </Typography>
                </Stack>
                <Stack gap="16px">
                    <Typography variant="h5" fontWeight="700">
                        Limitation of Liability
                    </Typography>
                    <Typography variant="body1">
                        Notwithstanding any damages that You might incur, the
                        entire liability of the Company and any of its suppliers
                        under any provision of these Terms and Your exclusive
                        remedy for all of the foregoing shall be limited to the
                        amount actually paid by You through the Service or 100
                        USD if You haven’t purchased anything through the
                        Service
                    </Typography>
                    <Typography variant="body1">
                        To the maximum extent permitted by applicable law, in no
                        event shall the Company or its suppliers be liable for
                        any special, incidental, indirect, or consequential
                        damages whatsoever (including, but not limited to,
                        damages for loss of profits, loss of data, or other
                        information, business interruption, personal injury, or
                        loss of privacy), even if the Company has been advised
                        of the possibility of such damages.
                    </Typography>
                    <Typography variant="body1">
                        Some states do not allow the exclusion of implied
                        warranties or limitation of liability for incidental or
                        consequential damages, which means some of the above
                        limitations may not apply. In these states, liability
                        will be limited to the greatest extent permitted by law.
                    </Typography>
                </Stack>
                <Stack gap="16px">
                    <Typography variant="h5" fontWeight="700">
                        &quot;AS IS&quot; and &quot;AS AVAILABLE&quot;
                        Disclaimer
                    </Typography>
                    <Typography variant="body1">
                        The Service is provided to You &quot;AS IS&quot; and
                        &quot;AS AVAILABLE&quot; and with all faults and defects
                        without warranty of any kind. The Company makes no
                        warranty that the Service will meet Your requirements,
                        be error-free, or uninterrupted.
                    </Typography>
                    <Typography variant="body1">
                        Some jurisdictions do not allow the exclusion of certain
                        warranties, so some of these exclusions may not apply to
                        You. In such cases, the exclusions set forth in this
                        section shall be applied to the greatest extent
                        enforceable under applicable law.
                    </Typography>
                </Stack>
                <Stack gap="16px">
                    <Typography variant="h5" fontWeight="700">
                        Governing Law
                    </Typography>
                    <Typography variant="body1">
                        The laws of the United States, excluding its conflicts
                        of law rules, shall govern these Terms and Your use of
                        the Service. Your use of the Service may also be subject
                        to other local, state, national, or international laws.
                    </Typography>
                </Stack>
                <Stack gap="16px">
                    <Typography variant="h5" fontWeight="700">
                        Disputes Resolution
                    </Typography>
                    <Typography variant="body1">
                        If You have any concern or dispute about the Service,
                        You agree to first try to resolve the dispute informally
                        by contacting the Company.
                    </Typography>
                </Stack>
                <Stack gap="16px">
                    <Typography variant="h5" fontWeight="700">
                        Changes to These Terms and Conditions
                    </Typography>
                    <Typography variant="body1">
                        We reserve the right to modify or replace these Terms at
                        any time. If a revision is material, we will provide at
                        least 30 days’ notice before any new terms take effect.
                        By continuing to access or use Our Service after those
                        revisions become effective, You agree to be bound by the
                        revised terms.
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default TermsPage
