import React, { FC, useState } from 'react'

import {
    Button,
    FormControl,
    FormHelperText,
    InputBase,
    InputLabel,
    Stack,
    Typography,
} from '@mui/material'
import { Warning, CheckCircle } from '@mui/icons-material'
import { useFormik } from 'formik'

import { Form } from 'components/common'
import { EmailFormScheme, EmailFormValues } from './index.schema'

interface EmailFormProps {
    onSubmit: (data: EmailFormValues) => void
    email: string
    isConfirmed: boolean
}

const EmailForm: FC<EmailFormProps> = ({ onSubmit, email, isConfirmed }) => {
    const [sent, setSent] = useState(false)

    const formik = useFormik({
        initialValues: { email },
        validationSchema: EmailFormScheme,
        onSubmit: values => onSubmit(values),
    })

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Stack gap="10px">
                <FormControl variant="standard">
                    <InputLabel shrink htmlFor="name">
                        Email
                    </InputLabel>
                    <InputBase
                        readOnly={sent}
                        disabled={sent}
                        autoFocus
                        id="email"
                        fullWidth
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.email && Boolean(formik.errors.email)
                        }
                    />
                    {formik.touched.email && formik.errors.email && (
                        <FormHelperText>{formik.errors.email}</FormHelperText>
                    )}
                    {sent && (
                        <FormHelperText
                            sx={{
                                padding: '10px',
                                display: 'flex',
                                direction: 'row',
                                color: '#a16207',
                                alignItems: 'center',
                                backgroundColor: '#fefce8',
                                gap: '16px',
                            }}
                        >
                            <Warning
                                sx={{
                                    fontSize: '16px',
                                    color: '#facc16',
                                }}
                            />
                            <Typography fontSize="14px">
                                Verification email sent to the new address.
                                Please check it.
                            </Typography>
                        </FormHelperText>
                    )}
                    {isConfirmed && (
                        <FormHelperText
                            sx={{
                                padding: '10px',
                                display: 'flex',
                                direction: 'row',
                                color: '#125e59',
                                alignItems: 'center',
                                backgroundColor: '#f0fdfa',
                                gap: '16px',
                            }}
                        >
                            <CheckCircle
                                sx={{
                                    fontSize: '16px',
                                    color: '#2ed4bf',
                                }}
                            />
                            <Typography fontSize="14px" fontWeight={600}>
                                Email Confirmed
                            </Typography>
                        </FormHelperText>
                    )}
                </FormControl>
                {formik.values.email !== email && !sent && (
                    <Stack direction="row" gap="12px">
                        <Button fullWidth type="submit">
                            Change
                        </Button>
                        <Button
                            onClick={() => formik.setFieldValue('email', email)}
                            fullWidth
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                    </Stack>
                )}
            </Stack>
        </Form>
    )
}
export default EmailForm
