import { useEffect, useRef, useState } from 'react'

import { Box, Button, Slider, Stack, Typography } from '@mui/material'
import { useMediaQuery } from '@mui/system'
import Cropper from 'react-easy-crop'

import getCroppedImage, { Crop, CroppedData } from 'utils/image.utils'
import { Dropzone, Image, Tag } from 'components/common'
import { FormDataState } from 'components/pages/user/CreateEventPage'

interface CoverUploaderProps {
    onSubmit: (file?: Blob, preview?: string) => void
    data: FormDataState
    isEditing?: boolean
}

const CoverUploader = ({
    onSubmit,
    data,
    isEditing = false,
}: CoverUploaderProps) => {
    const dropzoneRef = useRef(null)
    const lessThan1000px = useMediaQuery('(max-width:1000px)')

    const [file, setFile] = useState<File | null>(
        data.cover ? new File([data.cover], 'cover.png') : null
    )
    const [preview, setPreview] = useState<string | null>(data.preview || null)
    const [scale, setScale] = useState<number>(1)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Crop | null>(
        null
    )

    useEffect(() => {
        if (data.preview) {
            setPreview(data.preview)
        }
    }, [data.preview])

    const onFileSelect = () => {
        if (dropzoneRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dropzoneRef.current.open()
        }
    }

    const onCropComplete = (_: any, croppedAreaPixels: Crop) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const renderButtons = () => {
        return (
            <Stack
                gap="16px"
                direction="row"
                maxWidth={lessThan1000px ? '100%' : '60%'}
            >
                <Button
                    fullWidth
                    variant="outlined"
                    disabled={!file && !isEditing}
                    onClick={onFileSelect}
                >
                    Replace
                </Button>

                {!isEditing && !file && (
                    <Button
                        fullWidth
                        sx={{ padding: '8px 12px' }}
                        onClick={onFileSelect}
                    >
                        Upload Photo
                    </Button>
                )}
                {(isEditing || file) && (
                    <Button
                        fullWidth
                        sx={{ padding: '8px 12px' }}
                        onClick={async () => {
                            if (file) {
                                if (croppedAreaPixels) {
                                    const cropped: CroppedData =
                                        (await getCroppedImage(
                                            preview as string,
                                            croppedAreaPixels as Crop
                                        )) as CroppedData
                                    if (cropped) {
                                        onSubmit(
                                            cropped.file as Blob,
                                            cropped.preview as string
                                        )
                                    }
                                }
                            } else {
                                onSubmit()
                            }
                        }}
                    >
                        Next
                    </Button>
                )}
            </Stack>
        )
    }

    return (
        <Stack gap="8px" sx={{ maxWidth: lessThan1000px ? '100%' : '50%' }}>
            <Typography
                fontSize="12px"
                fontWeight={500}
                lineHeight="15px"
                color="#74747b"
            >
                Cover
            </Typography>
            <Stack gap="16px">
                <Box sx={{ position: 'relative' }}>
                    {/* <Image */}
                    {/*    src={data.preview} */}
                    {/*    alt="" */}
                    {/*    sx={{ */}
                    {/*        width: '100%', */}
                    {/*        height: '100%', */}
                    {/*    }} */}
                    {/* /> */}
                    <Dropzone
                        ref={dropzoneRef}
                        PreviewProps={{
                            sx: {
                                ...(preview &&
                                    file && {
                                        transform: `scale(${scale / 20})`,
                                    }),
                            },
                        }}
                        onSelect={files => {
                            const preview = URL.createObjectURL(files[0])
                            setFile(files[0])
                            setPreview(preview)
                        }}
                        onReject={() => {}}
                        multiple={false}
                        preview={preview}
                    />
                    {preview && file && (
                        <Cropper
                            crop={crop}
                            image={preview as string}
                            zoom={scale}
                            aspect={4 / 3}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            minZoom={0}
                            objectFit="contain"
                        />
                    )}
                </Box>

                {file && (
                    <Stack gap="3px" alignItems="center">
                        <Typography
                            color="#74747B"
                            fontSize="13px"
                            lineHeight="17px"
                            fontWeight={500}
                        >
                            Crop Image
                        </Typography>
                        <Slider
                            min={1}
                            max={3}
                            sx={{ maxWidth: '200px' }}
                            step={0.1}
                            defaultValue={1}
                            onChange={(e, value) => setScale(+value)}
                        />
                    </Stack>
                )}
                {renderButtons()}
            </Stack>
        </Stack>
    )
}

export default CoverUploader
