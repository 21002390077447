import { boolean, object, string } from 'yup'

import { emailValidator } from '../index.validators'

export interface LoginFormValues {
    email: string
    password: string
    rememberMe: boolean
}

export const LoginFormInitialValues = {
    email: '',
    password: '',
    rememberMe: true,
}

export const LoginFormScheme = object({
    email: string()
        .test(emailValidator)
        .required('Enter a valid email address'),
    password: string().required('Password is required'),
    rememberMe: boolean(),
})
