import React from 'react'

import { useDrawer } from 'hooks/common'
import { Drawer } from 'components/common'

const ReactiveDrawer = (): JSX.Element | null => {
    const {
        data: { component: Component, options, props },
    } = useDrawer()

    return Component && options ? (
        <Drawer {...options}>
            <Component {...props} />
        </Drawer>
    ) : null
}

export default ReactiveDrawer
