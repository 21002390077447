import { object, string, number } from 'yup'

export const ProfileUpdateValidationSchema = object({
    firstName: string().required('Enter first name'),
    lastName: string().required('Enter last name'),
    bio: string().max(255, 'Max characters limit is exceeded').optional(),
    grade: number().when('nonStudent', {
        is: true,
        then: schema => schema.nullable(),
        otherwise: schema =>
            schema
                .min(9, 'Should be higher than or equal to 9')
                .max(12, 'Should be less than or equal to 12')
                .test('grade', 'Enter grade', function (value) {
                    const { nonStudent } = this.parent

                    if (nonStudent) {
                        return true
                    }

                    return !!value
                })
                .required('Your should specify your grade'),
    }),
})
