import React, { useState } from 'react'

import { useFormik } from 'formik'
import {
    Box,
    Button,
    Stack,
    FormControl,
    InputLabel,
    InputBase,
    FormHelperText,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Form } from 'components/common'
import { SignUpFormScheme, SignUpFormInitialValues } from './index.schema'
import { useAuth } from 'hooks/auth'
import { Loader } from 'components/layout'
import { SignUpFormValues } from './index.types'
import { PasswordField } from '../../inputs'
import { authPaths } from 'routes'

interface SignUpProps {
    onSignUp: () => void
}

const SignUpForm = ({ onSignUp }: SignUpProps): JSX.Element => {
    const { isLoading, register } = useAuth()
    const navigate = useNavigate()

    const formik = useFormik<SignUpFormValues>({
        initialValues: SignUpFormInitialValues,
        validationSchema: SignUpFormScheme,
        onSubmit: (values: SignUpFormValues) => {
            register(values)
                .then(response => {
                    if (response?.success) {
                        onSignUp()
                    } else if (response?.errors?.[0]) {
                        formik.setFieldError('firstName', response.errors[0])
                    }
                })
                .catch(({ graphQLErrors }) => {
                    formik.setFieldError(
                        'email',
                        graphQLErrors[0].message.includes('already exists')
                            ? 'User with this email already exists'
                            : graphQLErrors[0].message
                    )
                })
        },
    })

    const isSubmitDisabled =
        (Object.keys(formik.touched).length > 1 &&
            Object.keys(formik.errors).length > 1) ||
        isLoading

    return (
        <Box>
            {isLoading && <Loader />}
            <Form onSubmit={formik.handleSubmit}>
                <Stack gap="24px">
                    <Stack gap="12px">
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="firstName">
                                First name
                            </InputLabel>
                            <InputBase
                                id="firstName"
                                fullWidth
                                name="firstName"
                                placeholder="First name"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.firstName &&
                                    Boolean(formik.errors.firstName)
                                }
                            />
                            {formik.touched.firstName &&
                                formik.errors.firstName && (
                                    <FormHelperText>
                                        {formik.errors.firstName}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="lastName">
                                Last name
                            </InputLabel>
                            <InputBase
                                id="lastName"
                                fullWidth
                                name="lastName"
                                placeholder="Last name"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.lastName &&
                                    Boolean(formik.errors.lastName)
                                }
                            />
                            {formik.touched.lastName &&
                                formik.errors.lastName && (
                                    <FormHelperText>
                                        {formik.errors.lastName}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="email">
                                Email
                            </InputLabel>
                            <InputBase
                                id="email"
                                fullWidth
                                name="email"
                                placeholder="e.g. example@email.com"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                            />
                            {formik.touched.email && formik.errors.email && (
                                <FormHelperText>
                                    {formik.errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="password1">
                                Password
                            </InputLabel>
                            <PasswordField
                                placeholder="Password"
                                value={formik.values.password1}
                                onChange={formik.handleChange}
                                name="password1"
                                id="password1"
                                error={
                                    formik.touched.password1 &&
                                    Boolean(formik.errors.password1)
                                }
                            />
                            {formik.touched.password1 &&
                                formik.errors.password1 && (
                                    <FormHelperText>
                                        {formik.errors.password1}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="password2">
                                Confirm Password
                            </InputLabel>
                            <PasswordField
                                placeholder="Password"
                                value={formik.values.password2}
                                onChange={formik.handleChange}
                                id="password2"
                                name="password2"
                                error={
                                    formik.touched.password2 &&
                                    Boolean(formik.errors.password2)
                                }
                            />
                            {formik.touched.password2 &&
                                formik.errors.password2 && (
                                    <FormHelperText>
                                        {formik.errors.password2}
                                    </FormHelperText>
                                )}
                        </FormControl>
                    </Stack>
                    <Stack gap="8px">
                        <Button
                            sx={{
                                padding: '11px 16px',
                                fontSize: '15px',
                            }}
                            variant="contained"
                            type="submit"
                            fullWidth
                            disabled={isSubmitDisabled}
                        >
                            Sign up
                        </Button>
                        <Button
                            onClick={() => navigate(`../${authPaths.login}`)}
                            sx={{
                                padding: '11px 16px',
                                fontSize: '15px',
                            }}
                            variant="text"
                            fullWidth
                        >
                            Back to Sign in
                        </Button>
                    </Stack>
                </Stack>
            </Form>
        </Box>
    )
}

export default SignUpForm
