import { useMutation, FetchResult } from '@apollo/client'

import {
    EmailSignInDocument,
    EmailSignInMutation,
    MutationEmailSignInArgs,
    EmailSignUpDocument,
    EmailSignUpMutation,
    EmailSignupInput,
    ResetPasswordDocument,
    ResetPasswordMutation,
    ResetPasswordInput,
    ResetPasswordConfirmDocument,
    ResetPasswordConfirmMutation,
    ResetPasswordConfirmInput,
    CheckEmailTokenLinkDocument,
    CheckEmailTokenLinkInput,
    CheckEmailTokenLinkMutation,
    EmailConfirmInput,
    EmailConfirmMutation,
    EmailConfirmDocument,
    ChangePasswordDocument,
    ChangePasswordMutation,
    ChangePasswordInput,
} from 'api/generated'
import { client } from 'api'
import { TokenStorage } from 'services'

export const useAuth = () => {
    const [checkEmailToken, { loading: checkEmailTokenIsProcessing }] =
        useMutation(CheckEmailTokenLinkDocument)

    const [emailConfirm, { loading: confirmEmailIsProcessing }] =
        useMutation(EmailConfirmDocument)

    const [signInApi, { loading: loginIsProcessing }] =
        useMutation(EmailSignInDocument)

    const [signUpApi, { loading: registerIsProcessing }] =
        useMutation(EmailSignUpDocument)

    const [resetPasswordApi, { loading: resetPasswordLoading }] = useMutation(
        ResetPasswordDocument
    )

    const [resetPasswordConfirmApi, { loading: resetPasswordConfirmLoading }] =
        useMutation(ResetPasswordConfirmDocument)

    const [changePasswordApi, { loading: changePasswordLoading }] = useMutation(
        ChangePasswordDocument
    )

    const login = (data: MutationEmailSignInArgs) => {
        return signInApi({ variables: data }).then(
            (response: FetchResult<EmailSignInMutation>) => {
                const result = response?.data?.emailSignIn
                if (result?.success) {
                    const { token, refreshToken } = result
                    TokenStorage.storeToken(token)
                    TokenStorage.storeRefreshToken(refreshToken)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.location = window.location.origin
                    return client.resetStore().then(() => {
                        return result
                    })
                }
                return result
            }
        )
    }

    const verifyEmailToken = (data: CheckEmailTokenLinkInput) => {
        return checkEmailToken({ variables: { input: data } }).then(
            (response: FetchResult<CheckEmailTokenLinkMutation>) =>
                response?.data?.checkEmailTokenLink
        )
    }

    const register = (data: EmailSignupInput) => {
        return signUpApi({ variables: { input: data } }).then(
            (response: FetchResult<EmailSignUpMutation>) =>
                response?.data?.emailSignUp
        )
    }

    const confirmEmail = (data: EmailConfirmInput) => {
        return emailConfirm({ variables: { input: data } }).then(
            (response: FetchResult<EmailConfirmMutation>) =>
                response?.data?.emailConfirm
        )
    }

    const logout = () => {
        TokenStorage.clear()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location = window.location.origin
    }

    const resetPassword = (data: ResetPasswordInput) => {
        return resetPasswordApi({ variables: { input: data } }).then(
            (response: FetchResult<ResetPasswordMutation>) =>
                response?.data?.resetPassword
        )
    }

    const resetPasswordConfirm = (data: ResetPasswordConfirmInput) => {
        return resetPasswordConfirmApi({ variables: { input: data } }).then(
            (response: FetchResult<ResetPasswordConfirmMutation>) =>
                response?.data?.resetPasswordConfirm
        )
    }

    const changePassword = (data: ChangePasswordInput) => {
        return changePasswordApi({ variables: { input: data } }).then(
            (response: FetchResult<ChangePasswordMutation>) =>
                response.data?.changePassword
        )
    }

    return {
        login,
        register,
        logout,
        resetPassword,
        resetPasswordConfirm,
        verifyEmailToken,
        confirmEmail,
        changePassword,
        isLoading:
            resetPasswordLoading ||
            resetPasswordConfirmLoading ||
            loginIsProcessing ||
            registerIsProcessing ||
            checkEmailTokenIsProcessing ||
            confirmEmailIsProcessing ||
            changePasswordLoading,
    }
}
