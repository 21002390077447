import React from 'react'

import {
    Drawer as MuiDrawer,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'

export interface DrawerProps {
    open?: boolean
    onClose: () => void
    children?: JSX.Element
    title?: string
}

const Drawer = ({
    children,
    onClose,
    open = true,
    title,
}: DrawerProps): JSX.Element => {
    return (
        <MuiDrawer
            anchor="right"
            open={open}
            onClose={onClose}
            sx={{ width: '860px' }}
        >
            <Stack
                sx={{
                    backgroundColor: '#F7F7F8',
                    height: '100%',
                }}
            >
                <Stack
                    padding="16px 24px"
                    gap="16px"
                    direction="row"
                    alignItems="center"
                    sx={{
                        backgroundColor: '#fff',
                    }}
                >
                    <IconButton
                        onClick={onClose}
                        sx={{
                            border: '1px solid #E4E4EB',
                            padding: '11px',
                            borderRadius: '6px',
                        }}
                    >
                        <Close />
                    </IconButton>
                    <Typography
                        fontSize="18px"
                        fontWeight={500}
                        lineHeight="22px"
                        color="#1d1e20"
                    >
                        {title}
                    </Typography>
                </Stack>
                {children}
            </Stack>
        </MuiDrawer>
    )
}

export default Drawer
