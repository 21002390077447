export const getFullName = (
    firstName: string | null = '',
    lastName: string | null = ''
): string => {
    let name = ''

    if (firstName) name += firstName
    if (firstName && lastName) name += ' '
    if (lastName) name += lastName

    return name
}
