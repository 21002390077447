import React, { ReactElement } from 'react'

import { Button, ButtonProps } from '@mui/material'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'

import { Input } from '../index'
import {
    MAX_PHOTO_SIZE,
    MAX_PHOTOS_LIMIT,
    PHOTO_ACCEPT_EXTENSIONS,
} from 'config/user.config'

export interface FilePickerProps {
    title?: string
    onChange: (files: File[]) => void
    onReject?: (fileRejections: FileRejection[], event: DropEvent) => void
    render?: (input: ReactElement, props: any) => ReactElement
    ButtonProps?: ButtonProps
}

const FilePicker = ({
    title,
    onChange,
    onReject,
    render,
    ButtonProps = {},
}: FilePickerProps): JSX.Element => {
    const { getInputProps } = useDropzone({
        multiple: true,
        maxFiles: MAX_PHOTOS_LIMIT,
        maxSize: MAX_PHOTO_SIZE,
        accept: PHOTO_ACCEPT_EXTENSIONS,
        onDropAccepted: onChange,
        onDropRejected: onReject,
        noClick: true,
        noKeyboard: true,
    })

    const renderInput = () => {
        return (
            <Input
                type="file"
                {...getInputProps()}
                sx={{
                    clip: 'rect(0 0 0 0)',
                    clipPath: 'inset(50%)',
                    height: 1,
                    overflow: 'hidden',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    whiteSpace: 'nowrap',
                    width: 1,
                }}
            />
        )
    }

    return render ? (
        render(renderInput(), { component: 'label' })
    ) : (
        <Button variant="contained" component="label" {...ButtonProps}>
            {title}
            {renderInput()}
        </Button>
    )
}

export default FilePicker
