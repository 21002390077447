import React, { ReactElement } from 'react'

import { Paper, Stack, Box, Typography, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

import { Loader } from '../index'
import { useDocumentTitle } from 'hooks/common'

interface AuthorizationLayoutProps {
    children: JSX.Element | JSX.Element[] | ReactElement | null
    title?: string
    sx?: SxProps<Theme>
    loading?: boolean
}

const AuthorizationLayout = ({
    children,
    title,
    sx,
    loading,
}: AuthorizationLayoutProps): JSX.Element => {
    useDocumentTitle(title)

    if (loading) {
        return <Loader />
    }

    return (
        <Box
            sx={{
                minHeight: '100vh',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: (theme: Theme) => theme.colors.background,
                position: 'relative',
                ...sx,
            }}
        >
            <Paper
                sx={(theme: Theme) => ({
                    width: `min(100%, 392px)`,
                    display: 'flex',
                    position: 'relative',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    padding: '32px',
                    borderRadius: '8px',
                    gap: '24px',
                    boxShadow:
                        '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
                    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
                        flexDirection: 'column',
                        margin: 0,
                    },
                })}
                elevation={0}
            >
                <Typography
                    sx={{
                        fontWeight: '600',
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: '24px',
                        lineHeight: '36px',
                    }}
                >
                    {title}
                </Typography>
                <Stack>{children}</Stack>
            </Paper>
        </Box>
    )
}
export default AuthorizationLayout
