import { object, string, number } from 'yup'

import { PASSWORD_REGEX } from '../index.validators'

export const SecurityUpdateFormScheme = object({
    password: string().required(),
    newPassword: string()
        .matches(
            PASSWORD_REGEX,
            'Password should contain uppercase and lowercase letters, numbers and special symbols(@$!%*?&) and have 8-32 length'
        )
        .required('Enter password'),
    newPassword2: string()
        .test('password_same', 'Passwords doesn’t match', function (value) {
            const { newPassword } = this.parent

            return newPassword === value
        })
        .required('Repeat password'),
})
