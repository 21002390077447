import React, { useEffect, useState } from 'react'

import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import {
    TextField,
    Autocomplete,
    ListItemText,
    ListItemIcon,
    ListItem,
    Stack,
    Typography,
    List,
} from '@mui/material'
import { KeyboardArrowDown, LocationOn } from '@mui/icons-material'

import { GOOGLE_PLACES_API_KEY } from 'config/env.config'
import { AddressType } from 'api/generated'

interface AddressComponent {
    long_name: string
    short_name: string
    types: string[]
}

interface Option {
    value: string
    label: string
}

interface LocationFieldProps {
    error: string | undefined
    touched: boolean | undefined
    defaultValue?: AddressType
    onChange: (details: AddressType) => void
}

const LocationField = ({
    error,
    touched,
    onChange,
    defaultValue,
    ...rest
}: LocationFieldProps) => {
    const [address, setAddress] = useState<Option | null>(null)

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        language: 'en-GB',
        options: {
            types: ['geocode'],
            componentRestrictions: { country: 'us' },
        },
        apiKey: GOOGLE_PLACES_API_KEY,
    })

    useEffect(() => {
        if (address)
            placesService?.getDetails(
                {
                    placeId: address.value,
                },
                (placeDetails: any) => {
                    const matches: AddressComponent[] =
                        placeDetails.address_components

                    onChange({
                        placeId: placeDetails.place_id,
                        location: {
                            lat: placeDetails.geometry.location.lat(),
                            lng: placeDetails.geometry.location.lng(),
                        },
                        building: matches.find(m =>
                            m.types.includes('street_number')
                        )?.long_name as unknown as number,
                        street: matches.find(m => m.types.includes('route'))
                            ?.long_name as string,
                        city: matches.find(m => m.types.includes('locality'))
                            ?.long_name as string,
                        state: matches.find((m: AddressComponent) =>
                            m.types.includes('administrative_area_level_1')
                        )?.long_name as string,
                        zipCode: Number(
                            matches.find((m: AddressComponent) =>
                                m.types.includes('postal_code')
                            )?.long_name as string
                        ),
                    })
                }
            )
    }, [address])

    const renderErrorText = (error: AddressType | string) => {
        if (typeof error === 'string') {
            return error
        }

        return `Address must be full. Missing fields: ${Object.keys(error).join(
            ', '
        )}`
    }

    return (
        <Autocomplete
            loading={isPlacePredictionsLoading}
            disableClearable
            options={placePredictions.map(s => ({
                label: s.description,
                value: s.place_id,
            }))}
            isOptionEqualToValue={(o: Option, v: Option) => o.value === v.value}
            value={address || undefined}
            onChange={(_, value) => setAddress(value)}
            renderOption={(props: any, option: Option) => {
                return (
                    <ListItem {...props}>
                        <ListItemIcon>
                            <LocationOn />
                        </ListItemIcon>
                        <ListItemText primary={option.label} />
                    </ListItem>
                )
            }}
            defaultValue={
                defaultValue?.placeId
                    ? {
                          value: defaultValue?.placeId as string,
                          label: `${defaultValue.building || ''} ${
                              defaultValue?.street
                          }, ${defaultValue?.state}, ${defaultValue?.city}, ${
                              defaultValue.zipCode
                          }`,
                      }
                    : (undefined as Option | undefined)
            }
            fullWidth
            renderInput={(params: any) => {
                return (
                    <TextField
                        {...params}
                        data-cy="location"
                        label="Location"
                        id="interests"
                        name="interests"
                        placeholder="Select"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e =>
                            getPlacePredictions({ input: e.target.value })
                        }
                        error={touched && Boolean(error)}
                        helperText={
                            touched &&
                            error &&
                            renderErrorText(error as AddressType)
                        }
                    />
                )
            }}
            popupIcon={<KeyboardArrowDown />}
            {...rest}
        />
    )
}
export default LocationField
