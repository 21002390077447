import { array, object, string } from 'yup'

export interface PhotoFormValues {
    name: string
    id?: string
    albumIds: string[]
}

export const PhotoFormInitialValues = {
    name: '',
    albumIds: [],
}

export const PhotoFormScheme = object({
    name: string()
        .max(32, 'Max characters limit is exceeded')
        .required('Required field'),
    albumIds: array(string()),
})
