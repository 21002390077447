import { object, string } from 'yup'

import { PASSWORD_REGEX } from '../index.validators'

export const CreatePasswordFormScheme = object({
    newPassword1: string()
        .matches(
            PASSWORD_REGEX,
            'Password should contain uppercase and lowercase letters, numbers and special symbols(@$!%*?&) and have 8-32 length'
        )
        .required('Enter password'),
    newPassword2: string()
        .test('password_same', 'Passwords should be equal', function (value) {
            const { newPassword1 } = this.parent

            return newPassword1 === value
        })
        .required('Confirm password'),
})
