import { array, boolean, number, object, string } from 'yup'

import { AddressType } from 'api/generated'

export interface ClubInformationFormValues {
    name: string
    description: string
    interests: number[]
    address: AddressType | null
    gradeFrom: number | null
    gradeTo: number | null
    preview?: string
    restrictedByGrade: boolean
}

export const ClubInformationFormInitialValues = {
    name: '',
    description: '',
    interests: [],
    address: null,
    gradeFrom: null,
    gradeTo: null,
    preview: '',
    restrictedByGrade: false,
}

export const ClubInformationFormScheme = object({
    name: string().required('Enter a name'),
    description: string()
        .max(1000, 'Max characters limit is exceeded')
        .required('Enter a description'),
    interests: array(number())
        .min(1, 'Your should specify at least 1 interest')
        .required('Your should specify your interests'),
    address: object({
        street: string().required('no street'),
        city: string().required(),
        state: string().required(),
        zipCode: string().required(),
        placeId: string().required(),
        location: object({
            lat: string().required(),
            lng: string().required(),
        }),
    }).required('Location is required field'),
    gradeFrom: number()
        .nullable()
        .when('restrictedByGrade', {
            is: true,
            then: schema =>
                schema
                    .min(9, 'Should be higher than or equal to 9')
                    .max(11, 'Should be less than or equal to 11')
                    .required('Should be higher than or equal to 9')
                    .test(
                        'grade_correct',
                        'Grade From must be lower than Grade To',
                        function (value) {
                            const { gradeTo } = this.parent

                            if (gradeTo) {
                                return gradeTo > (value || 0)
                            }
                            return true
                        }
                    ),
            otherwise: schema => schema.nullable(),
        }),
    gradeTo: number()
        .nullable()
        .when('restrictedByGrade', {
            is: true,
            then: schema =>
                schema
                    .min(10, 'Should be higher than or equal to 10')
                    .max(12, 'Should be less than or equal to 12')
                    .required('Should be less than or equal to 12')
                    .test(
                        'grade_correct',
                        'Grade To must be higher than Grade From',
                        function (value) {
                            const { gradeFrom } = this.parent

                            if (gradeFrom) {
                                return gradeFrom < (value || 0)
                            }
                            return true
                        }
                    ),
            otherwise: schema => schema.nullable(),
        }),
    restrictedByGrade: boolean().required(),
})
