import React, { FC } from 'react'

import {
    Button,
    FormControl,
    FormHelperText,
    InputBase,
    InputLabel,
    Stack,
} from '@mui/material'
import { useFormik } from 'formik'

import { Form } from 'components/common'
import {
    AlbumFormScheme,
    AlbumFormInitialValues,
    AlbumFormValues,
} from './index.schema'
import { AlbumType } from 'api/generated'

interface AlbumFormProps {
    onSubmit: (data: AlbumFormValues) => void
    album?: AlbumType
    onCancel: () => void
}

const AlbumForm: FC<AlbumFormProps> = ({ onSubmit, album, onCancel }) => {
    const formik = useFormik({
        initialValues: album
            ? { name: album?.name, id: album.id }
            : AlbumFormInitialValues,
        validationSchema: AlbumFormScheme,
        onSubmit,
    })

    return (
        <Form onSubmit={formik.handleSubmit} sx={{ width: '440px' }}>
            <Stack gap="24px">
                <FormControl variant="standard">
                    <InputLabel shrink htmlFor="name">
                        Album Name
                    </InputLabel>
                    <InputBase
                        autoFocus
                        id="name"
                        fullWidth
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.name && Boolean(formik.errors.name)
                        }
                    />
                    {formik.touched.name && formik.errors.name && (
                        <FormHelperText>{formik.errors.name}</FormHelperText>
                    )}
                </FormControl>
                <Stack direction="row" gap="12px">
                    <Button onClick={onCancel} fullWidth variant="outlined">
                        Cancel
                    </Button>
                    <Button fullWidth type="submit">
                        {album ? 'Save' : 'Create'}
                    </Button>
                </Stack>
            </Stack>
        </Form>
    )
}
export default AlbumForm
