import { Box, Stack, SxProps, Typography } from '@mui/material'
import { format } from 'date-fns'

import { ActivityType } from 'api/generated'
import noImage from 'assets/images/event-no-image.jpg'
import { Image } from 'components/common'

interface AnnouncementProps {
    data: ActivityType
    sx?: SxProps
    showClubName?: boolean
}

const Announcement = ({ data, showClubName, sx }: AnnouncementProps) => {
    return (
        <Stack
            data-test-id="announcement"
            gap="8px"
            sx={{
                padding: '20px 24px',
                borderRadius: '9px',
                border: '1px solid #E5E7EB',
                position: 'relative',
                overflow: 'hidden',
                ...sx,
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    width: '5px',
                    backgroundColor: `rgb(${Math.random() * 255}, ${
                        Math.random() * 255
                    }, ${Math.random() * 255})`,
                    position: 'absolute',
                    // borderRadius: '9px 0 0 9px',
                    top: 0,
                    bottom: 0,
                    left: '0',
                }}
            />
            {showClubName && data.name && (
                <Stack justifyContent="space-between" direction="row">
                    <Stack gap="10px" direction="row" alignItems="center">
                        <Image
                            sx={{
                                height: '30px',
                                width: '30px',
                                borderRadius: '4px',
                            }}
                            src={data.cover?.img40x40 || noImage}
                        />
                        <Typography
                            sx={{
                                fontSize: '13px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                color: '#1D1E20',
                            }}
                            className="highlight"
                        >
                            {data.name}
                        </Typography>
                    </Stack>
                    <Typography
                        fontSize="12px"
                        lineHeight="15px"
                        fontWeight={400}
                        color="#74747B"
                    >
                        {data.createdAt &&
                            format(new Date(data.createdAt), 'MM/dd/yyyy')}
                    </Typography>
                </Stack>
            )}
            <Stack justifyContent="space-between" direction="row">
                <Typography
                    fontSize="13px"
                    lineHeight="18px"
                    fontWeight={500}
                    color="#1D1E20"
                >
                    {data.title}
                </Typography>
                {!showClubName && (
                    <Typography
                        fontSize="12px"
                        lineHeight="15px"
                        fontWeight={400}
                        color="#74747B"
                    >
                        {data.createdAt &&
                            format(new Date(data.createdAt), 'MM/dd/yyyy')}
                    </Typography>
                )}
            </Stack>
            <Typography
                fontSize="13px"
                lineHeight="18px"
                fontWeight={400}
                color="#1D1E20"
            >
                {data.description}
            </Typography>
        </Stack>
    )
}

export default Announcement
