import React from 'react'

import { Button, Stack, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'

import { EventType } from 'api/generated'
import { Image } from 'components/common'

interface EventProps {
    event: Partial<EventType>
    type: 'created' | 'member'
    onClick: () => void
    width?: string
}

const Event = ({
    event,
    type,
    onClick,
    width = '289px',
}: EventProps): JSX.Element => {
    return (
        <Stack
            data-test-id="event"
            direction="row"
            padding="12px"
            gap="12px"
            key={event?.id}
            sx={{
                borderRadius: '5px',
                border: '1px solid #e5e7eb',
                transition: 'border 0.2s linear',
                cursor: 'pointer',
                width,
                '&:hover': {
                    border: '1px solid #000',
                },
            }}
            onClick={onClick}
        >
            <Image
                data-test-id="cover"
                src={event?.cover?.img117x84 as string}
                sx={{
                    height: '84px',
                    width: '117px',
                    objectFit: 'cover',
                    borderRadius: '9px',
                }}
            />
            <Stack gap="4px" alignItems="flex-start">
                <Typography
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="18px"
                    data-test-id="name"
                >
                    {event?.name}
                </Typography>
                <Button
                    data-test-id="action-button"
                    sx={(theme: Theme) => ({
                        justifyContent: 'flex-start',
                        padding: 0,
                        borderBottom: `1px solid ${theme.colors.primary}`,
                        minWidth: '0',
                        lineHeight: '17px',
                        borderRadius: 0,
                        fontSize: '13px',
                    })}
                    variant="text"
                >
                    {type === 'created' ? 'View' : 'Membership details'}
                </Button>
            </Stack>
        </Stack>
    )
}

export default Event
