import React, { Suspense } from 'react'

import { useRoutes } from 'react-router-dom'
import { Box } from '@mui/material'

import { authRoutes } from 'routes'
import { useVersionStamp } from 'hooks/common'

const Auth = (): JSX.Element => {
    useVersionStamp()

    const routes = useRoutes(authRoutes)
    return (
        <Box>
            <Suspense fallback={<Box />}>{routes}</Suspense>
        </Box>
    )
}

export default Auth
