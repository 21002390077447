import React from 'react'

import { Warning } from '@mui/icons-material'
import { Box } from '@mui/material'

import { Span } from 'components/common'

const GlobalError: React.FC = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 999,
            }}
        >
            <Warning
                sx={{
                    fontSize: '100px',
                    color: 'rgba(180,179,179,0.9)',
                    marginBottom: '15px',
                }}
            />
            <Span
                sx={{
                    color: 'rgba(159,158,158,0.9)',
                    fontSize: '24px',
                }}
            >
                Something went wrong
            </Span>
        </Box>
    )
}
export default GlobalError
