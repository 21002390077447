import React, { useState } from 'react'

import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { InView } from 'react-intersection-observer'

import banner from 'assets/images/banner.png'
import useHeaderHeight from 'hooks/common/useHeaderHeight'

const ReactInView = styled(InView)``
const BrowserButton = styled('button')``
const BrowserSVG = styled('svg')``

interface JoinProps {
    onSignUp: () => void
}

const Join = ({ onSignUp }: JoinProps) => {
    const [isImageInView, setImageInView] = useState(false)

    return (
        <Box
            id="join"
            sx={{
                position: 'relative',
                padding: '44px 0',
                background: '#F1F9FF',
                '@media (min-width: 601px)': { padding: '80px 0' },
            }}
        >
            <BrowserSVG
                xmlns="http://www.w3.org/2000/svg"
                width="302"
                height="283"
                fill="none"
                viewBox="0 0 302 283"
                className="ballLeft"
                sx={{
                    bottom: 0,
                    left: 0,
                    position: 'absolute',
                    width: '152.604px',
                    height: '152.604px',
                    '@media (min-width: 601px)': {
                        width: '221px',
                        height: '221px',
                    },
                    '@media (min-width: 901px)': {
                        width: '333px',
                        height: '333px',
                    },
                }}
            >
                <circle
                    cx="79.646"
                    cy="221.646"
                    r="219.5"
                    stroke="url(#paint0_linear_3_1312)"
                    strokeWidth="4"
                    opacity="0.2"
                    transform="rotate(39.203 79.646 221.646)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_3_1312"
                        x1="303.86"
                        x2="-160.231"
                        y1="170.849"
                        y2="146.118"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#1A237E" />
                        <stop offset="1" stopColor="#1A237E" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </BrowserSVG>
            <BrowserSVG
                xmlns="http://www.w3.org/2000/svg"
                width="222"
                height="302"
                fill="none"
                viewBox="0 0 222 302"
                sx={{
                    top: 0,
                    right: 0,
                    position: 'absolute',
                    width: '152.604px',
                    height: '152.604px',
                    '@media (min-width: 601px)': {
                        width: '221px',
                        height: '221px',
                    },
                    '@media (min-width: 901px)': {
                        width: '333px',
                        height: '333px',
                    },
                }}
            >
                <circle
                    cx="221.981"
                    cy="79.981"
                    r="219.5"
                    stroke="url(#paint0_linear_3_1313)"
                    strokeWidth="4"
                    opacity="0.2"
                    transform="rotate(166.186 221.981 79.98)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_3_1313"
                        x1="203.527"
                        x2="391.581"
                        y1="-140.316"
                        y2="257.079"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#1A237E" />
                        <stop offset="1" stopColor="#1A237E" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </BrowserSVG>
            <ReactInView
                onChange={inView => setImageInView(inView)}
                sx={{
                    maxWidth: '1142px',
                    width: '100%',
                    padding: '0 16px',
                    margin: 'auto',
                    boxSizing: 'border-box',
                    opacity: 0,
                    transition: '1.4s ease opacity',
                    ...(isImageInView && {
                        opacity: 1,
                    }),
                }}
            >
                <Box
                    sx={{
                        maxWidth: '700px',
                        margin: 'auto',
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            marginBottom: '24px',
                            color: '#000',
                            fontSize: '22px',
                            fontWeight: 600,
                            lineHeight: '34px',
                            textAlign: 'center',
                            letterSpacing: '0.88px',
                            '@media (min-width: 601px)': {
                                marginBottom: '32px',
                                lineHeight: '66px',
                                fontSize: '44px',
                            },
                        }}
                    >
                        We invite you to join us on this journey!
                    </Typography>

                    <BrowserButton
                        onClick={onSignUp}
                        sx={{
                            outline: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            display: 'flex',
                            padding: '12px 46px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            width: '100%',
                            borderRadius: '24px',
                            background: '#3fa4cf',
                            color: '#FDFDFD',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '24px',
                            transition: '0.3s ease all',
                            boxSizing: 'border-box',
                            margin: 'auto',
                            maxWidth: '350px',
                            '&:hover': {
                                backgroundColor: '#0077C2',
                                cursor: 'pointer',
                            },
                            '&:active': {
                                backgroundColor: '#005BA3',
                            },
                        }}
                    >
                        Sign up
                    </BrowserButton>
                </Box>
            </ReactInView>
        </Box>
    )
}

export default Join
