import React from 'react'

import { IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

interface NotificationBarProps {
    text: string
    variant?: string
}

const NotificationBar = ({
    text,
    variant = 'standard',
}: NotificationBarProps) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={theme => ({
                backgroundColor: variant === 'error' ? '#fff4f4' : '#F4F8FF',
                border:
                    variant === 'error'
                        ? `1px solid ${theme.colors.error}`
                        : '1px solid #E4E4EB',
                padding: '15px 24px',
                borderRadius: '7px',
                marginBottom: '36px',
            })}
        >
            <Typography fontSize="15px" lineHeight="18px" color="#1D1E20">
                {text}
            </Typography>
        </Stack>
    )
}

export default NotificationBar
