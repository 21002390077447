import React from 'react'

import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'

import { authPaths } from 'routes/index'
import Header from './components/Header'
import Banner from './components/Banner'
import AboutUs from './components/AboutUs'
import Join from './components/Join'
import Footer from './components/Footer'
import BottomBar from './components/BottomBar'

const LandingPage = (): JSX.Element => {
    const navigate = useNavigate()

    const onSignUp = (): void => {
        navigate(`../${authPaths.signUp}`)
    }

    const onSignIn = (): void => {
        navigate(`../${authPaths.login}`)
    }

    const onPrivacy = (): void => {
        navigate(`../${authPaths.privacy}`, { preventScrollReset: true })
    }

    const onTerms = (): void => {
        navigate(`../${authPaths.terms}`, { preventScrollReset: false })
    }

    return (
        <Stack
            sx={{
                overflow: 'clip',
            }}
        >
            <Header onSignUp={onSignUp} onSignIn={onSignIn} />
            <Banner onSignUp={onSignUp} />
            <AboutUs />
            <Join onSignUp={onSignUp} />
            <Footer
                onSignUp={onSignUp}
                onPrivacy={onPrivacy}
                onTerms={onTerms}
            />
            <BottomBar />
        </Stack>
    )
}

export default LandingPage
