import { adminPaths, userPaths } from 'routes'
import { KeyValType, Maybe } from 'api/generated'

interface Link {
    title: string
    path: string
    end?: boolean
}

interface LinksCollection {
    [key: string]: Link[]
}

export default function generateNavigationLinksByRole(
    role?: Maybe<KeyValType>
) {
    if (role) {
        const links: LinksCollection = {
            User: [
                {
                    title: 'Home',
                    path: userPaths.home,
                    end: true,
                },
                {
                    title: 'Users',
                    path: userPaths.users,
                },
                {
                    title: 'Events',
                    path: userPaths.events,
                },
                {
                    title: 'Clubs',
                    path: userPaths.clubs,
                },
            ],
            Admin: [
                {
                    title: 'System',
                    path: adminPaths.homepage,
                    end: true,
                },
                {
                    title: 'Users',
                    path: adminPaths.users,
                },
                {
                    title: 'Events',
                    path: adminPaths.events,
                    end: true,
                },
                {
                    title: 'Clubs',
                    path: adminPaths.clubs,
                    end: true,
                },
            ],
        }

        return links[role.val as string]
    }
    return []
}
