import React, { useState } from 'react'

import { useFormik } from 'formik'
import {
    Button,
    Stack,
    Box,
    Typography,
    TextField,
    InputLabel,
    InputBase,
    FormControl,
    FormHelperText,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { Theme } from '@mui/material/styles'

import { RouterLink, B, Form } from 'components/common'
import ForgotPasswordFormScheme from './index.schema'
import { useAuth } from 'hooks/auth'
import { Loader } from 'components/layout'
import { authPaths } from 'routes/index'

interface ForgotPasswordFormProps {
    onSuccess: () => void
}

const ForgotPasswordForm = ({
    onSuccess,
}: ForgotPasswordFormProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar()
    const { resetPassword, isLoading } = useAuth()
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgotPasswordFormScheme,
        onSubmit: values => {
            resetPassword(values).then(response => {
                if (response?.success) {
                    onSuccess()
                } else {
                    enqueueSnackbar('Email not found', { variant: 'error' })
                }
            })
        },
    })

    const isSubmitDisabled =
        (Object.keys(formik.touched).length > 1 &&
            Object.keys(formik.errors).length > 1) ||
        isLoading

    return (
        <Box>
            {isLoading && <Loader />}
            <Form
                onSubmit={formik.handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px',
                    minWidth: '300px',
                }}
            >
                <FormControl variant="standard">
                    <InputLabel shrink htmlFor="email">
                        Work email
                    </InputLabel>
                    <InputBase
                        autoFocus
                        id="email"
                        fullWidth
                        name="email"
                        placeholder="e.g. example@email.com"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.email && Boolean(formik.errors.email)
                        }
                    />
                    {formik.touched.email && formik.errors.email && (
                        <FormHelperText>{formik.errors.email}</FormHelperText>
                    )}
                </FormControl>
                <Stack gap="8px">
                    <Button
                        sx={{
                            padding: '11px 16px',
                            fontSize: '15px',
                        }}
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={isSubmitDisabled}
                    >
                        Remind Password
                    </Button>
                    <Button
                        onClick={() => navigate(`../${authPaths.login}`)}
                        sx={{
                            padding: '11px 16px',
                            fontSize: '15px',
                        }}
                        variant="text"
                        fullWidth
                    >
                        Back to Sign in
                    </Button>
                </Stack>
            </Form>
        </Box>
    )
}

export default ForgotPasswordForm
