import React from 'react'

import {
    Box,
    Button,
    Divider,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { LogoutOutlined, SearchOutlined } from '@mui/icons-material'

import generateNavigationLinksByRole from './index.links'
import { RouterNavLink, Image, GlobalSearchWindow } from 'components/common'
import logo from 'assets/images/logo.png'
import notification from 'assets/images/notification.svg'
import { useUser } from 'hooks/user'
import { userPaths } from 'routes'
import { useDialog, useDrawer } from 'hooks/common'
import { NotificationsLayout } from 'components/layout'
import { useNotifications } from 'hooks/notification'
import { useAuth } from '../../../hooks/auth'
import noAvatar from 'assets/images/event-no-image.jpg'

const NavLinkStyles = {
    fontSize: '13px',
    lineHeight: '24px',
    fontWeight: '500',
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#6B7280',
    '&:visited': {
        color: '#6B7280',
    },
}

const Header: React.FC = () => {
    const lessThan1100px = useMediaQuery('(max-width:1100px)')
    const lessThan800px = useMediaQuery('(max-width:800px)')
    const lessThan700px = useMediaQuery('(max-width:700px)')
    const lessThan550px = useMediaQuery('(max-width:550px)')

    const { user } = useUser()
    const { logout } = useAuth()

    const navigate = useNavigate()
    const { open, close } = useDrawer()
    const { open: openSearch, close: closeSearch } = useDialog()
    const { notifications } = useNotifications({
        ordering: '-created_at',
        isRead: false,
    })

    const isSuperAdmin = user?.role?.val === 'Admin'

    const handleOpenSearch = () => {
        openSearch({
            component: GlobalSearchWindow,
            props: {
                onClose: closeSearch,
            },
            options: {
                title: 'Search',
                onClose: closeSearch,
                sx: {
                    '& .MuiDialogContent-root': {
                        paddingBottom: '30px',
                    },
                },
            },
        })
    }

    const renderUserPanel = () => {
        return (
            <Stack
                direction="row"
                gap={lessThan550px ? '14px' : '25px'}
                alignItems="center"
            >
                <svg
                    data-test-id="chat"
                    onClick={() => navigate(`../${userPaths.chat}`)}
                    style={{
                        cursor: 'pointer',
                    }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="chat-alt-2">
                        <path
                            id="Vector"
                            d="M2.3999 5.99998C2.3999 5.36346 2.65276 4.75301 3.10285 4.30292C3.55293 3.85283 4.16338 3.59998 4.7999 3.59998H13.1999C13.8364 3.59998 14.4469 3.85283 14.897 4.30292C15.347 4.75301 15.5999 5.36346 15.5999 5.99998V10.8C15.5999 11.4365 15.347 12.0469 14.897 12.497C14.4469 12.9471 13.8364 13.2 13.1999 13.2H10.7999L7.1999 16.8V13.2H4.7999C4.16338 13.2 3.55293 12.9471 3.10285 12.497C2.65276 12.0469 2.3999 11.4365 2.3999 10.8V5.99998Z"
                            fill="#9398a2"
                        />
                        <path
                            id="Vector_2"
                            d="M17.9999 8.40002V10.8C17.9999 12.0731 17.4942 13.294 16.594 14.1941C15.6939 15.0943 14.473 15.6 13.1999 15.6H11.7935L9.67432 17.7204C10.0103 17.8992 10.3931 18 10.7999 18H13.1999L16.7999 21.6V18H19.1999C19.8364 18 20.4469 17.7472 20.897 17.2971C21.3471 16.847 21.5999 16.2365 21.5999 15.6V10.8C21.5999 10.1635 21.3471 9.55306 20.897 9.10297C20.4469 8.65288 19.8364 8.40002 19.1999 8.40002H17.9999Z"
                            fill="#9398a2"
                        />
                    </g>
                </svg>
                <Box
                    sx={{
                        position: 'relative',
                    }}
                >
                    {notifications.length > 0 && (
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                position: 'absolute',
                                top: '-3px',
                                right: '-3px',
                                backgroundColor: '#CE321E',
                                borderRadius: '50%',
                                padding: '3px',
                                width: '16px',
                                height: '16px',
                            }}
                        >
                            <Typography color="white" fontSize="9px">
                                {notifications?.length}
                            </Typography>
                        </Stack>
                    )}
                    <Image
                        data-test-id="notifications"
                        src={notification}
                        sx={{
                            cursor: 'pointer',
                            ...(lessThan550px && {
                                width: '18px',
                                height: '18px',
                            }),
                        }}
                        onClick={() => {
                            open({
                                component: NotificationsLayout,
                                options: {
                                    onClose: close,
                                    title: 'Notifications',
                                },
                                props: {
                                    onClose: close,
                                },
                            })
                        }}
                    />
                </Box>
                <Image
                    data-test-id="user-profile"
                    src={(user?.avatar?.img32x32 as string) || noAvatar}
                    onClick={() => navigate(`../${userPaths.profile}`)}
                    sx={theme => ({
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        border: '1px solid transparent',
                        cursor: 'pointer',
                        '&:hover': {
                            borderColor: theme.colors.primary,
                        },
                    })}
                />
            </Stack>
        )
    }

    const renderAdminPanel = () => {
        return (
            <Stack
                direction="row"
                gap={lessThan550px ? '14px' : '25px'}
                alignItems="center"
            >
                <IconButton onClick={logout} data-test-id="logout">
                    <LogoutOutlined
                        sx={{
                            fontSize: '24px',
                            ...(lessThan550px && {
                                fontSize: '18px',
                            }),
                        }}
                    />
                </IconButton>
            </Stack>
        )
    }

    return (
        <Stack
            sx={{
                padding: lessThan550px ? '14px' : '14px 32px',
                background: (theme: Theme) => theme.colors.paperColor,
                display: 'flex',
                justifyContent: lessThan700px
                    ? lessThan550px
                        ? 'center'
                        : 'flex-end'
                    : 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                borderBottom: '1px solid #E5E7EB',
            }}
        >
            {!lessThan700px && (
                <Stack direction="row" gap="35px" alignItems="center">
                    <Image
                        src={logo}
                        onClick={() => navigate('/')}
                        sx={{
                            cursor: 'pointer',
                            maxHeight: '25px',
                        }}
                    />
                    {lessThan800px ? (
                        <IconButton
                            sx={{
                                color: '#b0b0b0',
                                borderRadius: '8px',
                                border: '1px solid #c4c4c4',
                            }}
                            onClick={handleOpenSearch}
                            data-test-id="global-search"
                        >
                            <SearchOutlined />
                        </IconButton>
                    ) : (
                        <Button
                            sx={{
                                padding: '6px 15px',
                                color: '#b0b0b0',
                                borderRadius: '8px',
                                borderColor: '#c4c4c4',
                            }}
                            variant="outlined"
                            onClick={handleOpenSearch}
                            startIcon={<SearchOutlined />}
                            data-test-id="global-search"
                        >
                            {lessThan1100px
                                ? 'Search'
                                : 'Search: Users, Clubs, Events'}
                        </Button>
                    )}
                </Stack>
            )}
            <Stack
                direction="row"
                divider={<Divider flexItem orientation="vertical" />}
                alignItems="center"
                gap={lessThan550px ? '18px' : '36px'}
            >
                <Stack gap={lessThan550px ? '14px' : '36px'} direction="row">
                    {generateNavigationLinksByRole(user?.role).map(link => {
                        return (
                            <RouterNavLink
                                data-test-id={`navigation-link-${link.path.replace(
                                    '/',
                                    ''
                                )}`}
                                end={link.end}
                                key={link.path}
                                to={link.path}
                                sx={{
                                    ...NavLinkStyles,
                                    '&.active': {
                                        color: '#000',
                                    },
                                }}
                            >
                                {link.title}
                            </RouterNavLink>
                        )
                    })}
                </Stack>
                {isSuperAdmin ? renderAdminPanel() : renderUserPanel()}
            </Stack>
        </Stack>
    )
}

export default Header
