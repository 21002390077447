import React, { useEffect, useState } from 'react'

import { Box, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import logo from 'assets/images/logo.png'

const RouterLink = styled(Link)``
const BrowserLink = styled('a')``
const BrowserButton = styled('button')``
const BrowserListItem = styled('li')``

interface HeaderProps {
    onSignUp: () => void
    onSignIn: () => void
    onAbout?: () => void
    onContacts?: () => void
    onJoin?: () => void
}

const Header = ({
    onSignUp,
    onSignIn,
    onAbout,
    onContacts,
    onJoin,
}: HeaderProps) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [isAtTop, setIsAtTop] = useState(true)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                setIsAtTop(true)
            } else {
                setIsAtTop(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <Box
            sx={{
                position: 'sticky',
                zIndex: 3,
                top: '0',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: '82px',
                padding: '17px 0',
                boxSizing: 'border-box',
                transition: '.3s ease background-color',
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                '@media (min-width: 651px)': { padding: '17px 0' },
                ...(isAtTop && {
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                }),
            }}
        >
            <Box
                sx={{
                    maxWidth: '1142px',
                    width: '100%',
                    padding: '0 16px',
                    margin: 'auto',
                    boxSizing: 'border-box',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <RouterLink
                        to="/"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            ...(menuOpen && {
                                position: 'relative',
                                zIndex: 4,
                            }),
                        }}
                    >
                        <img
                            src={logo}
                            style={{ height: 'auto', width: '160px' }}
                            alt=""
                        />
                    </RouterLink>

                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <BrowserButton
                        type="button"
                        sx={{
                            margin: '0',
                            padding: '0',
                            outline: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            width: '30px',
                            height: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            zIndex: 1,
                            backgroundColor: 'inherit',
                            '@media (min-width: 651px)': { display: 'none' },
                            ...(menuOpen && {
                                position: 'fixed',
                                zIndex: 4,
                                right: '16px',
                                top: '30px',
                            }),
                        }}
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: '4px',
                                backgroundColor: '#000',
                                transition: 'transform 0.2s',
                                ...(menuOpen && {
                                    transform: 'translateY(8px) rotate(45deg)',
                                }),
                            }}
                        />
                        <Box
                            sx={{
                                width: '100%',
                                height: '4px',
                                backgroundColor: '#000',
                                transition: 'transform 0.2s',
                                ...(menuOpen && { opacity: 0 }),
                            }}
                        />
                        <Box
                            sx={{
                                width: '100%',
                                height: '4px',
                                backgroundColor: '#000',
                                transition: 'transform 0.2s',
                                ...(menuOpen && {
                                    transform:
                                        'translateY(-8px) rotate(-45deg)',
                                }),
                            }}
                        />
                    </BrowserButton>

                    <Stack
                        sx={{
                            textAlign: 'center',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '24px',
                            '@media (min-width: 651px)': { display: 'none' },
                            position: 'fixed',
                            zIndex: 3,
                            width: '100vw',
                            minHeight: '100vh',
                            background: '#FFF',
                            left: '0',
                            top: '0',
                            right: '0',
                            bottom: '0',
                            padding: '0 16px',
                            transition: '0.3s ease transform',
                            transform: 'translateX(100%)',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                            paddingTop: '100px',
                            ...(menuOpen && {
                                transform: 'translateX(0)',
                            }),
                        }}
                    >
                        <BrowserListItem
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0',
                                padding: '0',
                            }}
                        >
                            <BrowserLink
                                sx={{
                                    textDecoration: 'none',
                                    color: '#000',
                                    position: 'relative',
                                    whiteSpace: 'nowrap',
                                    '&::after': {
                                        position: 'absolute',
                                        content: "''",
                                        width: '0',
                                        bottom: '0',
                                        left: '0',
                                        height: '1px',
                                        backgroundColor: '#000',
                                        transition: '0.3s ease all',
                                    },
                                    '&:hover': {
                                        '&::after': { width: '100%' },
                                    },
                                }}
                                onClick={() => {
                                    setMenuOpen(false)
                                    onAbout?.()
                                }}
                                tabIndex={menuOpen ? 0 : -1}
                                href="#about"
                            >
                                About us
                            </BrowserLink>
                        </BrowserListItem>
                        <BrowserListItem
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0',
                                padding: '0',
                            }}
                        >
                            <BrowserLink
                                sx={{
                                    color: '#000',
                                    position: 'relative',
                                    whiteSpace: 'nowrap',
                                    textDecoration: 'none',

                                    '&::after': {
                                        position: 'absolute',
                                        content: "''",
                                        width: '0',
                                        bottom: '0',
                                        left: '0',
                                        height: '1px',
                                        backgroundColor: '#000',
                                        transition: '0.3s ease all',
                                    },
                                    '&:hover': {
                                        '&::after': { width: '100%' },
                                    },
                                }}
                                onClick={() => {
                                    setMenuOpen(false)
                                    onContacts?.()
                                }}
                                tabIndex={menuOpen ? 0 : -1}
                                href="#footer"
                            >
                                Contacts
                            </BrowserLink>
                        </BrowserListItem>

                        <BrowserListItem
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0',
                                padding: '0',
                            }}
                        >
                            <BrowserLink
                                sx={{
                                    color: '#000',
                                    position: 'relative',
                                    whiteSpace: 'nowrap',
                                    textDecoration: 'none',

                                    '&::after': {
                                        position: 'absolute',
                                        content: "''",
                                        width: '0',
                                        bottom: '0',
                                        left: '0',
                                        height: '1px',
                                        backgroundColor: '#000',
                                        transition: '0.3s ease all',
                                    },
                                    '&:hover': {
                                        '&::after': { width: '100%' },
                                    },
                                }}
                                onClick={() => {
                                    setMenuOpen(false)
                                    onJoin?.()
                                }}
                                tabIndex={menuOpen ? 0 : -1}
                                href="#join"
                            >
                                Join KoolYard
                            </BrowserLink>
                        </BrowserListItem>
                    </Stack>

                    <Stack
                        direction="row"
                        sx={{
                            gap: '40px',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '24px',
                            li: {
                                display: 'flex',
                                alignItems: 'center',
                                a: {},
                            },
                            display: 'none',
                            '@media (min-width: 651px)': { display: 'flex' },
                        }}
                    >
                        <BrowserListItem
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0',
                                padding: '0',
                            }}
                        >
                            <BrowserLink
                                onClick={() => {
                                    if (onAbout) {
                                        onAbout()
                                    }
                                }}
                                sx={{
                                    color: '#000',
                                    position: 'relative',
                                    whiteSpace: 'nowrap',
                                    textDecoration: 'none',
                                    '&::after': {
                                        position: 'absolute',
                                        content: "''",
                                        width: '0',
                                        bottom: '0',
                                        left: '0',
                                        height: '1px',
                                        backgroundColor: '#000',
                                        transition: '0.3s ease all',
                                    },
                                    '&:hover': {
                                        '&::after': { width: '100%' },
                                    },
                                }}
                                href="#about"
                            >
                                About us
                            </BrowserLink>
                        </BrowserListItem>
                        <BrowserListItem
                            sx={{
                                margin: '0',
                                padding: '0',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <BrowserLink
                                onClick={() => {
                                    if (onContacts) {
                                        onContacts()
                                    }
                                }}
                                sx={{
                                    color: '#000',
                                    position: 'relative',
                                    whiteSpace: 'nowrap',
                                    textDecoration: 'none',

                                    '&::after': {
                                        position: 'absolute',
                                        content: "''",
                                        width: '0',
                                        bottom: '0',
                                        left: '0',
                                        height: '1px',
                                        backgroundColor: '#000',
                                        transition: '0.3s ease all',
                                    },
                                    '&:hover': {
                                        '&::after': { width: '100%' },
                                    },
                                }}
                                href="#footer"
                            >
                                Contacts
                            </BrowserLink>
                        </BrowserListItem>

                        <BrowserListItem
                            sx={{
                                margin: '0',
                                padding: '0',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <BrowserButton
                                onClick={onSignIn}
                                sx={{
                                    outline: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    padding: '12px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px',
                                    width: '100%',
                                    borderRadius: '24px',
                                    background: '#3fa4cf',
                                    color: '#FDFDFD',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                    transition: '0.3s ease all',
                                    boxSizing: 'border-box',
                                    '&:hover': {
                                        backgroundColor: '#0077C2',
                                        cursor: 'pointer',
                                    },
                                    '&:active': {
                                        backgroundColor: '#005BA3',
                                    },
                                }}
                            >
                                Sign in
                            </BrowserButton>
                        </BrowserListItem>
                        <BrowserListItem
                            sx={{
                                margin: '0',
                                padding: '0',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <BrowserButton
                                onClick={onSignUp}
                                sx={{
                                    outline: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    padding: '12px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px',
                                    width: '100%',
                                    borderRadius: '24px',
                                    background: '#3fa4cf',
                                    color: '#FDFDFD',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                    transition: '0.3s ease all',
                                    boxSizing: 'border-box',
                                    textWrap: 'no-wrap',
                                    '&:hover': {
                                        backgroundColor: '#0077C2',
                                        cursor: 'pointer',
                                    },
                                    '&:active': {
                                        backgroundColor: '#005BA3',
                                    },
                                }}
                            >
                                Sign up
                            </BrowserButton>
                        </BrowserListItem>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default Header
