import React from 'react'

import { useFormik } from 'formik'
import {
    Box,
    Button,
    Stack,
    Typography,
    FormControl,
    InputLabel,
    InputBase,
    FormHelperText,
    Checkbox,
    FormControlLabel,
    Switch,
} from '@mui/material'
import { useSnackbar } from 'notistack'

import {
    PostSignUpFormScheme,
    PostSignUpFormInitialValues,
} from './index.schema'
import { PostSignUpFormValues } from './index.types'
import { Form, Image } from 'components/common'
import { useUser, useUserAPI } from 'hooks/user'
import { SchoolType } from 'api/generated'
import { AutocompleteField } from 'components/inputs'
import checkbox from '../../../assets/images/checkbox.svg'
import checkboxChecked from '../../../assets/images/checkbox-checked.svg'

interface PostSignUpProps {
    onSuccess: () => void
    schools: SchoolType[]
}

const PostSignUpForm = ({
    onSuccess,
    schools,
}: PostSignUpProps): JSX.Element => {
    const { update } = useUserAPI()
    const { enqueueSnackbar } = useSnackbar()
    const { refetch } = useUser()

    const formik = useFormik<PostSignUpFormValues>({
        initialValues: PostSignUpFormInitialValues,
        validationSchema: PostSignUpFormScheme,
        onSubmit: (values: PostSignUpFormValues) => {
            update({
                input: {
                    ...(!values.nonStudent && {
                        school: values.school,
                        grade: values.grade,
                    }),
                    location: values.location,
                    nonStudent: values.nonStudent,
                },
            }).then(response => {
                if (response?.success) {
                    enqueueSnackbar('Your profile was successfully set up', {
                        variant: 'success',
                    })
                    refetch()
                    onSuccess()
                }
            })
        },
    })

    const isSubmitDisabled =
        Object.keys(formik.touched).length > 1 &&
        Object.keys(formik.errors).length > 1

    return (
        <Box>
            <Form onSubmit={formik.handleSubmit}>
                <Stack gap="24px">
                    <Stack gap="15px">
                        <Typography fontSize="15px">
                            Let’s quickly setup your environment
                        </Typography>
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="location">
                                Enter your ZIP code
                            </InputLabel>
                            <InputBase
                                id="location"
                                fullWidth
                                name="location"
                                placeholder="Location"
                                value={formik.values.location}
                                onChange={formik.handleChange}
                                type="number"
                                error={
                                    formik.touched.location &&
                                    Boolean(formik.errors.location)
                                }
                            />
                            {formik.touched.location &&
                                formik.errors.location && (
                                    <FormHelperText>
                                        {formik.errors.location}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="grade">
                                Choose your School from the dropdown
                            </InputLabel>
                            <AutocompleteField
                                disabled={formik.values.nonStudent}
                                options={
                                    schools?.map(s => ({
                                        label: s.name as string,
                                        value: s.id,
                                    })) || []
                                }
                                placeholder="School"
                                onChange={v => {
                                    formik.setFieldValue('school', v)
                                }}
                                state={{
                                    touched: !!formik.touched.school,
                                    errors: formik.errors.school,
                                }}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="grade">
                                Enter your Grade (9-12)
                            </InputLabel>
                            <InputBase
                                disabled={formik.values.nonStudent}
                                id="grade"
                                fullWidth
                                name="grade"
                                placeholder="Grade"
                                value={formik.values.grade}
                                onChange={formik.handleChange}
                                type="number"
                                inputProps={{
                                    min: 9,
                                    max: 12,
                                }}
                                error={
                                    formik.touched.grade &&
                                    Boolean(formik.errors.grade)
                                }
                            />
                            {formik.touched.grade && formik.errors.grade && (
                                <FormHelperText>
                                    {formik.errors.grade}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControlLabel
                            sx={{
                                color: '#74747b',
                            }}
                            control={
                                <Switch
                                    value={formik.values.nonStudent}
                                    defaultChecked={formik.values.nonStudent}
                                    name="nonStudent"
                                    onChange={formik.handleChange}
                                />
                            }
                            label="I am an adult"
                        />
                    </Stack>
                    <Button
                        sx={{
                            padding: '11px 16px',
                            fontSize: '15px',
                        }}
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={isSubmitDisabled}
                    >
                        Get started
                    </Button>
                </Stack>
            </Form>
        </Box>
    )
}

export default PostSignUpForm
