import React, { lazy } from 'react'

const LoginPage = lazy(
    () =>
        import(
            /* webpackChunkName: "LoginPage" */ 'components/pages/auth/LoginPage'
        )
)

const LandingPage = lazy(
    () =>
        import(
            /* webpackChunkName: "LandingPage" */ 'components/pages/auth/LandingPage'
        )
)

const PrivacyPage = lazy(
    () =>
        import(
            /* webpackChunkName: "PrivacyPage" */ 'components/pages/auth/PrivacyPage'
        )
)

const TermsPage = lazy(
    () =>
        import(
            /* webpackChunkName: "TermsPage" */ 'components/pages/auth/TermsPage'
        )
)
const ResetPasswordPage = lazy(
    () =>
        import(
            /* webpackChunkName: "ResetPasswordPage" */ 'components/pages/auth/ResetPasswordPage'
        )
)
const SignUpPage = lazy(
    () =>
        import(
            /* webpackChunkName: "SignUpPage" */ 'components/pages/auth/SignUpPage'
        )
)

const ForgotPasswordPage = lazy(
    () =>
        import(
            /* webpackChunkName: "ForgotPasswordPage" */ 'components/pages/auth/ForgotPasswordPage'
        )
)
const EmailVerificationPage = lazy(
    () =>
        import(
            /* webpackChunkName: "EmailVerificationPage" */ 'components/pages/auth/EmailVerificationPage'
        )
)

const NotFoundPage = lazy(
    () =>
        import(
            /* webpackChunkName: "NotFoundPage" */ 'components/pages/common/NotFoundPage'
        )
)

const authPaths = {
    landing: '/',
    privacy: 'privacy',
    terms: 'terms',
    home: 'auth',
    login: 'login',
    signUp: 'sign-up',
    emailVerification: 'email-verification',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
}

const authRoutes = [
    {
        path: authPaths.login,
        element: <LoginPage />,
        name: 'login',
    },
    {
        path: authPaths.landing,
        element: <LandingPage />,
        name: 'landing',
    },
    {
        path: authPaths.privacy,
        element: <PrivacyPage />,
        name: 'privacy',
    },
    {
        path: authPaths.terms,
        element: <TermsPage />,
        name: 'terms',
    },
    {
        path: authPaths.signUp,
        element: <SignUpPage />,
        name: 'signUp',
    },
    {
        path: authPaths.forgotPassword,
        element: <ForgotPasswordPage />,
        name: 'forgotPassword',
    },
    {
        path: authPaths.emailVerification,
        element: <EmailVerificationPage />,
        name: 'emailVerification',
    },
    {
        path: authPaths.resetPassword,
        element: <ResetPasswordPage />,
        name: 'resetPassword',
    },
    {
        path: '*',
        element: <NotFoundPage />,
        name: 'notFound',
    },
]
export { authPaths, authRoutes }
