import React from 'react'

import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import logoSmallGreen from 'assets/images/logo-small-green.png'

const RouterLink = styled(Link)``
const BrowserLink = styled('a')``
const BrowserList = styled('ul')``
interface FooterProps {
    onSignUp: () => void
    onPrivacy: () => void
    onTerms: () => void
}

const Footer = ({ onSignUp, onTerms, onPrivacy }: FooterProps) => {
    return (
        <Box
            id="footer"
            sx={{
                padding: '56px 0 32px',
                borderBottom: '1px solid #D8E0EA',
                '@media (min-width: 601px)': { padding: '80px 0 40px' },
            }}
        >
            <Box
                sx={{
                    maxWidth: '1142px',
                    width: '100%',
                    padding: '0 16px',
                    margin: 'auto',
                    boxSizing: 'border-box',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    '@media (min-width: 601px)': {
                        display: 'flex',
                        justifyContent: 'space-between',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        gridColumn: '1/3',
                        marginBottom: '24px',
                        '@media (min-width: 601px)': { marginBottom: '0' },
                    }}
                >
                    <RouterLink to="/">
                        <img
                            src={logoSmallGreen}
                            alt=""
                            style={{ width: '60px', height: 'auto' }}
                        />
                    </RouterLink>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                    }}
                    className="sitemapWrapper"
                >
                    <Typography
                        sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '26px',
                        }}
                        className="title"
                    >
                        Site map
                    </Typography>

                    <BrowserList
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            margin: '0',
                            padding: '0',
                            listStyle: 'none',
                        }}
                    >
                        <BrowserLink
                            sx={{
                                textDecoration: 'none',
                                color: '#000',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                width: 'fit-content',
                                position: 'relative',
                                '&::after': {
                                    position: 'absolute',
                                    content: "''",
                                    width: '0',
                                    bottom: '0',
                                    left: '0',
                                    height: '1px',
                                    backgroundColor: '#000',
                                    transition: '0.3s ease all',
                                },
                                '&:hover': {
                                    '&::after': { width: '100%' },
                                },
                            }}
                            className="navLink"
                            href="#about"
                        >
                            About us
                        </BrowserLink>
                        <BrowserLink
                            sx={{
                                textDecoration: 'none',

                                color: '#000',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                width: 'fit-content',
                                position: 'relative',
                                '&::after': {
                                    position: 'absolute',
                                    content: "''",
                                    width: '0',
                                    bottom: '0',
                                    left: '0',
                                    height: '1px',
                                    backgroundColor: '#000',
                                    transition: '0.3s ease all',
                                },
                                '&:hover': {
                                    '&::after': { width: '100%' },
                                },
                            }}
                            className="navLink"
                            href="#footer"
                        >
                            Contacts
                        </BrowserLink>
                        <BrowserLink
                            onClick={onSignUp}
                            sx={{
                                textDecoration: 'none',

                                color: '#000',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                width: 'fit-content',
                                position: 'relative',
                                cursor: 'pointer',
                                '&::after': {
                                    position: 'absolute',
                                    content: "''",
                                    width: '0',
                                    bottom: '0',
                                    left: '0',
                                    height: '1px',
                                    backgroundColor: '#000',
                                    transition: '0.3s ease all',
                                },
                                '&:hover': {
                                    '&::after': { width: '100%' },
                                },
                            }}
                            className="navLink"
                            href="#join"
                        >
                            Join KoolYard
                        </BrowserLink>
                        <BrowserLink
                            onClick={onPrivacy}
                            sx={{
                                textDecoration: 'none',

                                color: '#000',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                width: 'fit-content',
                                cursor: 'pointer',
                                position: 'relative',
                                '&::after': {
                                    position: 'absolute',
                                    content: "''",
                                    width: '0',
                                    bottom: '0',
                                    left: '0',
                                    height: '1px',
                                    backgroundColor: '#000',
                                    transition: '0.3s ease all',
                                },
                                '&:hover': {
                                    '&::after': { width: '100%' },
                                },
                            }}
                            className="navLink"
                        >
                            Privacy Policy
                        </BrowserLink>
                        <BrowserLink
                            onClick={onTerms}
                            sx={{
                                textDecoration: 'none',
                                cursor: 'pointer',

                                color: '#000',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                width: 'fit-content',
                                position: 'relative',
                                '&::after': {
                                    position: 'absolute',
                                    content: "''",
                                    width: '0',
                                    bottom: '0',
                                    left: '0',
                                    height: '1px',
                                    backgroundColor: '#000',
                                    transition: '0.3s ease all',
                                },
                                '&:hover': {
                                    '&::after': { width: '100%' },
                                },
                            }}
                            className="navLink"
                        >
                            Terms of Use
                        </BrowserLink>
                    </BrowserList>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                    }}
                    className="contactsWrapper"
                >
                    <Typography
                        sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '26px',
                        }}
                        className="title"
                    >
                        Contact us
                    </Typography>

                    <BrowserList
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                            margin: '0',
                            padding: '0',
                            listStyle: 'none',
                        }}
                    >
                        <li>
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <BrowserLink
                                sx={{
                                    textDecoration: 'none',

                                    color: '#000',
                                    transition: '.3s ease color',
                                    '&:hover': { color: '#3fa4cf' },
                                }}
                                href="tel:+14088901690"
                                rel="noreferrer"
                            >
                                +1 (408)890-1690
                            </BrowserLink>
                        </li>
                        <li>
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <BrowserLink
                                sx={{
                                    textDecoration: 'none',

                                    color: '#000',
                                    transition: '.3s ease color',
                                    '&:hover': { color: '#3fa4cf' },
                                }}
                                href="mailto:chonvo@gmail.com"
                                rel="noreferrer"
                            >
                                chonvo@gmail.com
                            </BrowserLink>
                        </li>
                    </BrowserList>
                </Box>
            </Box>
        </Box>
    )
}

export default Footer
